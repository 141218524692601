<template>
	<div class="tag">
		<div v-if="!record_loaded">
			<h1>Loading..</h1>
		</div>
		<div class="bg-dark text-light p-3" v-if="record_loaded">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-person-circle /> Tag #{{record.tag_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<form>
				<div class="form-row">
					<div class="col-md-8">
						<div class="form-group">
							<label for="tag_name">Tag Name</label>
							<input id="tag_name" type="text" class="form-control" v-model="record.tag_name" />
							<small class="form-text text-muted">The name of your tag.</small>
						</div>
						<div class="form-group">
							<label for="category">Description</label>
							<input id="category" type="text" class="form-control" v-model="record.description" />
							<small class="form-text text-muted">Describe the purpose of this tag.</small>
						</div>
					</div>
					<div class="col-md-4 text-right">
						<h5>Statistics</h5>
						<div>
							<label>Current Toys Tagged: </label>
							<br />
							<span style="font-size: 44pt;" class="text-muted">{{record.usage_count}}</span>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Tag',
	components: {},
	data: function(){
		return {
			options: {
				type: [
					{value:'system',text:'System User'},
					{value:'interventionist',text:'Consultant'},
					],
				accounts: [],
			},
			loading: false,
			record_loaded: false,
			record: {}
		}
	},
	methods: {
		cancel: function(){
			this.loading = true;
			this.$router.push('/admin/tags');
		},
		remove: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Tag',
				'Are you sure?',
				'danger',
				function(answer){
					if( answer ) {
						v.api.delete({
							url: '/admin/tag/'+String(v.record.tag_id),
							method: 'delete',
							success: function(){
								v.toast('Tag Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								let msg = 'Tag Deletion Failed';
								v.toast(msg,'danger');
								console.error(msg,x,s,m)
							},
						});
					}
				});
		},
		save: function(){
			let v = this;
			let id = (v.record.tag_id ? v.record.tag_id : 0);
			v.loading = true;
			v.api.post({
				url: '/admin/tag/'+String(id),
				data: v.record,
				success: function(){
					v.toast('Tag Changes Saved','success');
					setTimeout(v.cancel,500);
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Tag Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load: function(id){
			var v = this;
			if(id==0)
			{
				v.record_loaded = true;
				return;
			}		
			v.record_loaded = false;
			v.api.get({
				url: '/admin/tag/'+String(id),
				success: function(response){
					for(var i in response){
						if( i != 'usage_count' )
							v.record[i] = response[i]
					}
					v.toast('Tag Loaded')
					v.record_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Tag Failed to Load';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
		fetch_accounts: function(){
			var v = this;
			v.api.get({
				url: '/admin/accounts',
				success: function(response){
					for(var i in response){
						v.options.accounts.push( {
							value: response[i].account_id,
							text: response[i].account_name,
						});
					}
				},
				fail: function(x,s,m){
					let msg = 'Unable to load Accounts';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
	},
	mounted: function(){
		var id = this.$route.params.id;
		this.fetch_accounts();
		this.load(id)
	},
}
</script>
