<template>
	<div class="toygrid">
		<div class="row">
			<div class="col-6 col-md-3">
				<div class="mt-5">
					<div class="d-flex justify-content-between">
						<strong>Filters</strong>
						<button type="button" class="btn btn-sm btn-warning" @click="reset_search();evt_filter_click();" title="Clear Filters"><i class="bi bi-x-circle"></i></button>
					</div>
					<hr />
					<form @submit.prevent="evt_filter_click()">
						<div class="form-group">
							<div class="input-group">
								<!-- <input type="text" id="keyword" placeholder="by keyword" class="form-control" v-model="search_keyword" @keyup="evt_filter_click"> -->
								<input type="text" id="keyword" placeholder="by keyword" class="form-control" v-model="search_keyword" required>
								<div class="input-group-append">
									<button type="search" class="btn btn-sm btn-success" title="Apply Keyword Filter"><i class="bi bi-search"></i></button>
								</div>
							</div>
						</div>
					</form>
					<hr />

					<strong>Status:</strong>

					<div class="custom-control custom-radio" v-for="opt in possess_options" :key="opt.value">
						<input class="custom-control-input" type="radio" :value="opt.value" :id="'status_'+opt.value" v-model="selected" @click="evt_filter_click()">
						<label class="custom-control-label" :for="'status_'+opt.value">{{opt.text}}</label>
					</div>

					<div v-show="selected=='client'">
						<fieldset class="form-group">
							<div class="custom-control custom-checkbox" v-for="client in sortedToyClients" :key="client.client_id">
								<input type="checkbox" class="custom-control-input" :id="'client' + client.client_id" :value="client.client_id" v-model="clientChecked" @change="evt_filter_click()">
								<label class="custom-control-label" :for="'client' + client.client_id">{{client.last_name}}, {{client.first_name}}</label>
							</div>
						</fieldset>
					</div>
					<hr />

					<strong>Categories</strong>
					<div class="custom-control custom-checkbox" v-for="c in categories" :key="c.category_name">
						<input class="custom-control-input" type="checkbox" v-model="search_categories" :value="c.category_id" :id="'categorychk_'+c.category_id" @click="evt_filter_click()">
						<label class="custom-control-label" :for="'categorychk_'+c.category_id">{{c.category_name}}</label>
					</div>
					<!-- {{search_categories}} -->
					<hr />
					<strong>Tags</strong>
					<div class="custom-control custom-checkbox" v-for="tag in tags" :key="tag.tag_id">
						<input class="custom-control-input" type="checkbox" v-model="search_tags" :value="tag.tag_id" :id="'tagchk_'+tag.tag_id" @click="evt_filter_click()">
						<label class="custom-control-label" :for="'tagchk_'+tag.tag_id">{{tag.tag_name}}</label>
					</div>
				</div>
			</div>
			<div class="col-6 col-md-9">
				<div class="card mt-3" v-if="selected=='me'">
					<div class="card-header"><b>Give to Client</b></div>

					<div class="card-body">
						<div class="row">
							<div class="col-12 col-md-6 col-lg-8">The selected toys will be given to the selected client.</div>

							<div class="col-12 col-md-6 col-lg-4">
								<select id="client" class="form-control custom-select custom-select-sm" v-model="giveto_client_id">
									<option :value="null" disabled selected>Choose Client</option>
									<option v-for="(client,key) in sortedClients" :value="client.client_id" :key="key">{{client.first_name}} {{client.last_name}}</option>
								</select>
							</div>
						</div>
					</div>

					<div class="card-footer text-center">
						<button :disabled="giveto_client_id == null || checkoutToys.length == 0" @click="bulk_checkout" class="btn btn-primary">Confirm</button>
					</div>
				</div>

				<div class="card mt-3" v-show="selected=='client'">
					<div class="card-header"><b>Pick up Toys</b></div>
					<div class="card-body">The selected toys will be returned to your possession.</div>
					<div class="card-footer text-center">
						<button :disabled="checkinToys.length == 0" @click="bulk_checkin" class="btn btn-primary">Confirm</button>
					</div>
				</div>

				<div class="row" v-show="loading">
					<div class="col">
						<div class="text-center">
							<img src="/img/loading.gif" />
						</div>
					</div>
				</div>

				<div class="row mt-5" v-if="filterToys.length > 0 && !loading">
					<div class="col-12 col-md-4 col-lg-3 mb-5" v-for="toy in filterToys" :key="toy.toy_id + toy.toy_id" v-show="toy_visible(toy.toy_id)">
						<div class="d-flex align-items-start flex-column h-100 text-center">
							<div class="d-block w-100">
								<a :href="toy.primary_photo_url" target="_blank">
									<img :src="toy.primary_photo_url + '?s=100'" :alt="toy.primary_photo_caption" class="rounded" />
								</a>
							</div>
							<div class="mt-4 mb-3 d-block w-100">
								{{toy.toy_name}}<br><span class="text-monospace">{{toy.toy_number}}</span>
							</div>

							<div class="mt-auto d-block w-100">
								<div v-if="toy.status=='to_client'" class="d-block w-100">
									<div class="badge badge-info">Client</div>
									{{toy.client_first_name}} {{toy.client_last_name}}<br><i>{{toy.most_recent.entry_date | duration}}</i>
								</div>
								<div v-else-if="toy.status=='to_interventionist'" class="d-block w-100">
									<div class="badge badge-info">EI</div>
									{{toy.interventionist_firstname}} {{toy.interventionist_lastname}}<br><i>{{toy.most_recent.entry_date | duration}}</i>
								</div>

								<div class="custom-control custom-checkbox big-checkbox mt-3" v-show="selected=='me'">
									<input type="checkbox" class="custom-control-input" :value="toy.toy_id" :name="'toclient_' + toy.toy_id" :id="'toclient_' + toy.toy_id" v-model="checkoutToys">
									<label class="custom-control-label" :for="'toclient_' + toy.toy_id"></label>
								</div>

								<div class="custom-control custom-checkbox big-checkbox mt-3" v-show="selected=='client'">
									<input type="checkbox" class="custom-control-input" :value="toy.toy_id" :name="'checkin_' + toy.toy_id" :id="'checkin_' + toy.toy_id" v-model="checkinToys">
									<label class="custom-control-label" :for="'checkin_' + toy.toy_id"></label>
								</div>

								<button type="button" class="btn btn-primary mt-3" @click="add_to_cart(toy.toy_id)" v-show="toy.status == 'to_library'"><i class="bi bi-cart"></i> Add to Pick List</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-5" v-else-if="!loading">
					<div class="col-12">
						<div class="alert alert-warning text-center">Sorry, no toys were found matching the selected filters.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	.custom-control.custom-checkbox.big-checkbox
	{
		min-height: 2rem;
		padding-left: 2rem;
	}

	.custom-checkbox.big-checkbox .custom-control-label::after,
	.custom-checkbox.big-checkbox .custom-control-label::before
	{
		top: 0;
		left: -1.75rem;
		width: 1.5rem;
		height: 1.5rem;
	}
</style>

<script>

// import Cookies from 'js-cookie'
import {EventBus} from '../main'
import moment from 'moment'
// import UserStatusConditionChangeDialog from '../components/UserStatusConditionChangeDialog'

export default {
	name: 'ToyGrid',
	components:{
		// UserStatusConditionChangeDialog
	},
	props: {
	},
	filters:{
		duration: val=>{
			let now = moment()
			let entry = moment(val)

			let duration = now.diff(entry,'days');
			let timeString = " days"
			if(duration < 1)
			{
				duration = now.diff(entry,'hours');
				timeString = " hours"
				if(duration < 1)
				{
					duration = now.diff(entry,'minutes');
					timeString = " minutes"
				}
			}
		return duration + timeString;
		
		},
	},
	data: function(){
		return {
			toys: [],
			hidden: [],
			loading: false,
			p_toy_id:'',
			picklist:[],
			search_tags: [],
			search_categories: [],
			clients:[],
			toyClients:[],
			clientChecked:[],
			giveto_client_id: null,
			categories: [],
			tags: [],
			checkoutToys:[],
			checkinToys:[],
			search_keyword: null,
			selected: 'all',
			timeouts: {
				filter: null,
			},
			possess_options: [
				{ text: 'All Toys', value: 'all' },
				{ text: 'In Stock', value: 'library'},
				{ text: 'Checked Out By Me', value: 'me' },
				{ text: 'Out With Clients', value: 'client' }]
		}
	},
	computed: {
		filterToys() {
			var v = this;
			return v.toys;      
		},
		sortedClients: function()
		{
			var temp = this.clients;

			return temp.sort(function(a, b)
			{
				if(a.last_name > b.last_name)
				{
					return 1;
				}
				else if(a.last_name < b.last_name)
				{
					return -1;
				}
				else
				{
					if(a.first_name > b.first_name)
					{
						return 1;
					}
					else if(a.first_name < b.first_name)
					{
						return -1;
					}
					else
					{
						return 0;
					}
				}
			});
		},
		sortedToyClients: function()
		{
			var temp = this.toyClients;

			return temp.sort(function(a, b)
			{
				if(a.last_name > b.last_name)
				{
					return 1;
				}
				else if(a.last_name < b.last_name)
				{
					return -1;
				}
				else
				{
					if(a.first_name > b.first_name)
					{
						return 1;
					}
					else if(a.first_name < b.first_name)
					{
						return -1;
					}
					else
					{
						return 0;
					}
				}
			});
		}
	},
	methods: {
		log(e){
			console.log(e)
		},
		reset(){
			let v = this;

			v.checkoutToys = [];
			v.checkinToys = [];
		},
		reset_search(){
			let v = this;
			v.selected = 'all';
			v.search_categories = [];
			v.search_tags = [];
			v.search_keyword = "";
			v.clientChecked = [];
		},
		load_picks(){
			let v = this
			v.api.get({
				url: '/admin/picklist',
				success: async function(response){
					v.picklist = response

					// Items in the pick list are hidden.
					for(let i=0; i<v.picklist.length; i++)
					{
						v.hidden.push(v.picklist[i].toy_id);
					}

					EventBus.$emit('cart',v.picklist)
				},
				fail: function(x,s,m){
					let msg = 'Unable to load user picks'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_toys(){
			let v = this

			v.loading = true
			v.toys = [];
			
			// If we're not filtering by clients, make sure the client filter is empty.
			if(v.selected != "client")
			{
				v.clientChecked = [];
			}

			let filters = {
				keyword: v.search_keyword,
				tags: v.search_tags,
				categories: v.search_categories,
				status: v.selected,
				clients: v.clientChecked,
			}

			v.api.post({
				url: '/admin/mytoys',
				data: JSON.stringify(filters),
				contentType: 'json',
				success: function(response){
					v.loading = false;
					v.toys = response;

					// If viewing all clients, we'll pull toy clients and "my toys" count from there.
					if(v.selected == "all")
					{
						let clients = []

						response.forEach(function (item) {
							if(item.status=='to_client' && item.client_id)
							{
								let client = {
									client_id:item.client_id,
									first_name:item.client_first_name,
									last_name:item.client_last_name
									}
								clients.push(client)
							}
						});

						v.toyClients = [...new Map(clients.map(item => [item["client_id"], item])).values()];

						EventBus.$emit('mytoys',v.toys)
					}
					// Otherwise, we'll refresh toy clients and "my toys" count manually using a 2nd call.
					else
					{
						v.load_toy_clients();
					}

					v.reset();
				},
				fail: function(x,s,m){
					console.error('Failed to load toys',x,s,m)
					v.loading = false
				},
			});
		},
		load_toy_clients(){
			let v = this

			v.loading = true
			v.toyClients = [];

			let filters = {
				keyword: "",
				tags: [],
				categories: [],
				status: "all",
				clients: [],
			}

			v.api.post({
				url: '/admin/mytoys',
				data: JSON.stringify(filters),
				contentType: 'json',
				success: function(response){
					v.loading = false;

					let clients = []

					response.forEach(function (item) {
						if(item.status=='to_client' && item.client_id)
						{
							let client = {
								client_id:item.client_id,
								first_name:item.client_first_name,
								last_name:item.client_last_name
								}
							clients.push(client)
						}
					});

					v.toyClients = [...new Map(clients.map(item => [item["client_id"], item])).values()];

					EventBus.$emit('mytoys', response)
				},
				fail: function(x,s,m){
					console.error('Failed to load clients',x,s,m)
					v.loading = false
				},
			});
		},
		toy_visible(toy_id){
			return this.hidden.indexOf(toy_id) == -1;
		},
		load_tags(){
			let v = this;
			this.api.get({
				url: '/admin/toptags',
				success: function(response){
					v.tags = []
					if(response.length)
					{
						for(let i in response){
							let row = response[i]
							v.tags.push(row)
						}
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Tags'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				}
			})
		},
		load_clients(){
			let v = this;
			v.api.get({
				url: '/myclients',
				success: function(response){
					if(response.length)
						for(let i in response)
							v.clients.push(response[i])
				},
				fail: function(x,s,m){
					let msg = 'Unable to retrieve list of Clients'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_categories(){
			let v = this;
			this.api.get({
				url: '/admin/categories',
				success: function(response){
					v.categories = []
					if(response.length)
					{
						for(let i in response)
						{
							let category = response[i]
							v.categories.push(category)
						}
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			});
		},
		evt_filter_click(){
			let v = this;
			if(v.timeouts.filter)
				clearTimeout(v.timeouts.filter)
			v.timeouts.filter = setTimeout(v.load_toys,300);
		},
		bulk_checkout(){
			let v = this;

			let record = {
				client_id:v.giveto_client_id,
				toys:v.checkoutToys
			}

			v.nerivon_confirm(
				'Confirm Dropped Off',
				'Click YES to confirm that you are checking out the selected toys to the selected client.',
				'success',
				function(answer){
				if( answer )
				{
					v.api.post({
						url: '/bulk_checkout',
						data: JSON.stringify(record),
						method: 'post',
						dataType: 'json',
						success: function(){
							let msg = 'Toys checked out'
							v.toast(msg,'success')
							v.load_toys();
						},
						fail: function(x,s,m){
							let msg = 'Saving Changes Failed'
							v.toast(msg,'danger')
							console.error(msg,x,s,m)
						}
					});
				}
			});
		},
		bulk_checkin(){
			let v = this;

			let record = {
				toys:v.checkinToys
			}

			v.nerivon_confirm(
				'Confirm Picked Up',
				'Click YES to confirm that you are now in posession of the selected toys.',
				'success',
				function(answer){
				if( answer )
				{
				v.api.post({
					url: '/bulk_checkin',
					data: JSON.stringify(record),
					method: 'post',
					dataType: 'json',
					success: function(){
						let msg = 'Toys checked in'
						v.toast(msg,'success')
						v.load_toys();
					},
					fail: function(x,s,m){
						let msg = 'Saving Changes Failed'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
						}
					});
				}
			});
		},
		add_to_cart(toy_id){
			let v = this;
			let data = {toy_id:toy_id}
			v.api.post({
				url: '/admin/picklist/'+String(toy_id),
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				success: function(){
					v.hidden.push(toy_id);
					let msg = 'Toy added to cart'
					v.load_picks()
					v.toast(msg,'success')
				},
				fail: function(x,s,m){
					console.error(x,s,m)
				},
			})
		}
	},
	mounted: function(){
		this.load_picks();
		this.load_toys();
		this.load_tags();
		this.load_categories();
		this.load_clients();
	},
}

</script>