<template>
	<div class="toy-cart-item">
		<div class="row mt-5" v-if="toy_loaded">
			<div class="col-6">
				<h4>{{toy.toy_name}} - [{{toy.toy_number}}]</h4>
			</div>
			<div class="col-6 text-right">
				<button type="button" class="btn btn-sm btn-danger" @click="remove_from_picklist(toy.pick_list_id)">
					<i class="bi bi-cash"></i> Remove From Picks
				</button>
				<button type="button" class="ml-2 btn btn-sm btn-success" @click="move_to_cart(toy.pick_list_id)">
					<i class="bi bi-arrow-down"></i> Move to Cart
				</button>
			</div>
		</div>
	</div>
</template>

<style>
	@media screen and ( max-width: 599px ) {
		img.toythumb { width: 55px; }
	}
	@media screen and ( min-width: 600px) {
		img.toythumb { width: 150px;}
	}
</style>

<script>
// import Cookies from 'js-cookie'
import {EventBus} from '../main'
export default {
	name: 'ToyCartBasic',
	props: {
		pick: Object,
		// toy_id: Number,
		// pick_list_id: Number
	},
	data: function(){
		return {
			toy: {
				pick_list_id:''
			},
			toy_loaded: false,
			statuses: {
				'to_library':'Added to Library',
				'to_client':'Given to Client',
				'to_interventionist':'Withdrawn by Consultant',
				'to_retirement':'Retired/Removed from Library',
				'to_vendor_repair':'Sent to Vendor for Repair',
				'to_vendor_return':'Sent to Vendor for Return',
				'to_vendor_cleaning':'Sent to Vendor for Cleaning'
			},
			conditions: {
				'good': 'Good',
				'needs_repair': 'Needs Repair',
				'needs_replacement':'Needs Replacement',
				'needs_cleaning':'Needs Cleaning',
				'missing_pieces':'Missing Pieces',
			},
		}
	},
	methods: {
		load_toy(x){
			let v = this;
			v.toy_loaded = false;
			v.api.get({
				url: '/toy/'+String(x.toy_id),
				success: function(response){
					v.toy = response;
					v.toy.note = '';
					v.toy_loaded = true;
					v.toy.pick_list_id = x.pick_list_id
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Toy '+String(x.toy_id);
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			})
		},
		remove_from_picklist(){
			var v = this;
			v.loading = false;
			v.api.delete({
				url: '/admin/picklist/'+String(v.toy.pick_list_id),
				success: function(){
					let msg = 'Toy removed from pick list'
					v.$emit('deleted')
					EventBus.$emit('updateCart',msg)
				},
				fail: function(x,s,m){
					v.toast('Deletion Failed','danger');
					console.error('User Deletion Failed',x,s,m)
				},
			});
		},
		move_to_cart(){
			//TODO: Moves the toy from the picklist to the actual cart for checkout
			let v = this;
			v.loading = false;

		},
		status_text(status){
			if(status)
				return this.statuses[status]
			else
				return ''
		},
		status_class(status){
			if(!status)
				return 'badge-light';
			if( status == 'to_library' ){
				return 'badge-success';
			}
			if( status == 'to_interventionist' || status == 'to_client' ){
				return 'badge-warning';
			}
			return 'badge-danger';
		},
		condition_text(condition){
			if(condition)
				return this.conditions[condition]
			else
				return ''
		},
		condition_class(condition){
			if( condition == 'good' ){
				return 'badge-success'
			}
			return 'badge-warning'
		},
	},
	mounted: function(){
		this.load_toy(this.pick)
	},
}

</script>