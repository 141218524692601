<template>
	<div id="check-in" class="container pt-5">
		<ConditionModal ref="conditionmodal" @select="condition_select" />
		<h2>Check In Toys to the Library</h2>
		<div class="alert alert-info">
			Toys in your possession can be checked into the library by scanning. You MUST scan toy labels or manually enter their numbers here in order to confirm them into the library.
		</div>
		<div class="row">
			<div class="col-md-8">
				<h3>My Toys</h3>
				<div v-if="mytoys.length > 0">
					<div v-for="toy in mytoys" :key="toy.toy_id">
						<ToyCartItemAdvanced 
							:toy_id="toy.toy_id" 
							:allow_condition_changes="true"  
							@condition="condition_click" 
							/>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<ScanPanel @scan="add_scanned_toy" v-show="toy_number_index.length>0" />
			</div>
		</div>
		<hr />
		<h2>Scanned Return List</h2>
		<p>Items below this line will be recorded as returned to the library after you press the <strong>Return Toys</strong></p>
		<hr />
		<div v-if="cart.length > 0 && cart_visible">
			<div v-for="toy_id in cart" :key="toy_id">
				<ToyCartItemAdvanced 
					:toy_id="toy_id" 
					:allow_condition_changes="true"  
					@condition="condition_click" 
					/>
			</div>
		</div>
		<div v-if="cart.length == 0">
			<p class="text-center">There are no toys in your Check-In.</p>
			<p class="text-center">Scan or Enter some Toy Numbers Above to add toys to your Check-In List.</p>
		</div>
		<div class="text-right" v-if="cart.length > 0">
			<hr />
			<button type="button" class="btn btn-info btn-lg" @click="confirm_checkin"><i class="bi bi-check2-circle"></i> Confirm Check-In {{cart.length}} Toy(s)</button>
		</div>
	</div>
</template>
<script>
	// import Cookies from 'js-cookie'
	import {EventBus} from '../main'
	import ConditionModal from '@/components/ConditionModal.vue'
	import ScanPanel from '@/components/ScanPanel.vue'
	import ToyCartItemAdvanced from '@/components/ToyCartItemAdvanced.vue'

	export default{
		name: 'CheckIn',
		components: {ScanPanel,ToyCartItemAdvanced,ConditionModal},
		data(){return{
			mytoys_loaded: false,
			cart_visible: true,
			mytoys: [],
			toy_number_index: [],
			cart: [],
		}},
		methods: {
			condition_click(toy_id,condition){
				this.$refs.conditionmodal.open(toy_id,condition)
			},
			condition_select(toy_id,condition,notes){
				let v = this
				if( toy_id && condition )
				{
					v.api.post({
						url: '/condition/'+String(toy_id),
						data: { condition: condition, notes: notes },
						dataType: 'text',
						success(){
							v.$nextTick(()=>{
								v.load_my_toys()
								EventBus.$emit('mytoys')
							})
						},
						fail(){
							v.toast('Failed to Update Condition','danger')
						},
					})
				}
				return toy_id
			},
			confirm_checkin(){
				let v = this
				v.nerivon_confirm('Check In',"Before you confirm checkin, please ensure that you have considered the condition of the toys you are checking in. Are they complete? Are they Damaged? If so, cancel this process and report the condition changes before proceeding. Otherwise, click yes to proceed and checkin the toys in your Return list","warning",function(answer){
					if( answer == true )
					{
						v.api.post({
							url: '/checkin',
							data: JSON.stringify(v.cart),
							dataType: 'text',
							contentType: 'json',
							success(){
								v.cart = [] // clear the cart
								EventBus.$emit('cart')
								EventBus.$emit('mytoys')
								v.nerivon_alert('Checkin Complete','Your items are now in the library.','success')
							},
							fail(){
								v.toast('A problem has occurred, your toys have not been checked in.','danger')
							},
						})
					}
				})
			},
			add_scanned_toy(scanned_text){
				// Toy Number will be the user assigned number for the toy, like "S210B" or some such.
				// Have to lookup the toy by number first and then pass on the toy ID
				let v = this
				let toy_id = false
				let toy_status = 'to_library'

				for(let index of this.toy_number_index)
				{
					if( String(index.toy_number).toLowerCase() == String(scanned_text).toLowerCase() )
					{
						toy_id = index.toy_id
						toy_status = index.status
						break;
					}
				}

				if( toy_status == 'to_library' )
				{
					this.nerivon_alert('Already in Library','This toy has already been returned to the library. It cannot be returned again.','warning')
					return
				}

				if( toy_id === false )
				{
					window.alert('Bad Toy Number, '+String(scanned_text)+' was not found in the index.')
					return
				}

				// If the toy is a mytoys item, remove it from the mytoys
				if( v.mytoys.length )
				{
					let index = -1
					for(let i in v.mytoys)
					{
						let mytoy = v.mytoys[i]
						if( mytoy.toy_id == toy_id )
						{
							index = i
							break
						}
					}
					if( index > -1 )
						v.mytoys.splice(index,1)
				}

				// Add the New Toy ID to the Cart Items List
				// Prevent Duplicates by not adding it twice if it's already present
				if( this.cart.length )
				{
					for(let cart_toy_id of this.cart)
					{
						if( cart_toy_id == toy_id )
							return // Just end the function, don't push to cart
					}
				}
				this.cart.push(toy_id)
			},
			load_my_toys(){
				let v = this
				
				v.cart_visible = false
				v.mytoys_loaded = false
				v.mytoys = []

				v.api.get({
					url: '/mytoys',
					success(response){
						v.mytoys = []
						// Convert response to object
						if( response.mytoys )
						{
							if( response.mytoys.length > 0 )
							{
								for(let toy_id of response.mytoys)
									v.mytoys.push({toy_id: toy_id})
							}
						}
						v.mytoys_loaded = true
						v.cart_visible = true
					},
					fail: function(x,s,m){
						let msg = 'Unable to load my toys list'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
						v.cart_visible = true
					},
				})
			},
			load_toy_number_index(){
				let v = this
				v.api.get({
					url: '/toy_number_index',
					success: function(response){
						v.toy_number_index = response
					},
				})
			}
		},
		mounted(){
			this.load_toy_number_index()
			this.load_my_toys()
		},
	}
</script> 
