 <template>
	<div class="modal" tabindex="-1" id="give-modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Give to Client/Family</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<p>Select the client/family that you wish to indicate has been given this toy.</p>
					<select class="form-control" :size="rowcount" v-model="selected_client">
						<option v-for="c in clients" :key="c.client_id" :value="c.client_id">
							<i class="bi bi-person"></i> {{c.first_name}} {{c.last_name}}
						</option>
					</select>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					<button type="button" class="btn btn-primary" @click="confirm_click">Confirm</button>
				</div>
			</div>
		</div>
	</div>
 </template>
 <script>
	import jQuery from 'jquery'

	export default{
		name: 'GiveModal',
		data(){return{
			clients: [],
			selected_client: null,
			rowcount: 10,
		}},
		methods: {
			open(){
				this.load_clients(function(){
					jQuery('#give-modal').modal('show')
				})
			},
			close(){
				jQuery('#give-modal').modal('hide')
			},
			load_clients(callback){
				// Retrieves a list of clients for the current user
				let v = this
				v.api.get({
					url: '/myclients',
					success: function(response){
						v.clients = response
						if(callback)
							callback()
					}
				})
			},
			confirm_click(){
				// Takes the selected client and returns it through an emit event
				let client = null
				for(client of this.clients)
				{
					if( client.client_id == this.selected_client )
						break
				}
				if( client != null )
					this.$emit('select',client.client_id)
				this.close()
			},
		},
		mounted(){
			if( this.isMobile() )
				this.rowcount = 5
		},
	}
</script>