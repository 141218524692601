<template>
	<div id="syspanel">
		<div class="container mt-5">
			<h1>System Panel</h1>
			<div class="card" style="width: 60rem;padding:20px;">
				<h5>Re-build Toy History Cache</h5>
				<p>The toy history cache is used to provide faster search processes for the dashboards throughout the application. 
					Sometimes this may get a bit jumbled and have to be regenerated from scratch to fix issues with toy filtering 
				and display in the list views.</p>
				<button v-if="!rebuilding_toy_cache" type="button" class="btn btn-lg btn-info" @click="rebuild_toy_cache">Rebuild Toy History Cache Now</button>
				<button v-if="!rebuilding_toy_cache" type="button" class="btn btn-lg btn-danger" @click="repair_toy_status">Repair Toy Caches</button>
				<div v-if="rebuilding_toy_cache" @click="rebuilding_toy_cache=false">
					<div class="text-center"><img src="/img/loading-widebar.gif" /></div>
					<p class="text-center">Rebuilding Toy History Cache, please wait...</p>
				</div>
			</div>
			<div class="card" style="width: 60rem;padding:20px;">
				<h5>Import Photos in Bulk from Server Directory</h5>
				<hr />
				<!-- <button type="button" @click="start_cycle">Start {{cycle_count}}</button> -->
				<form>
					<div class="form-row">
						<div class="col">
							<h5>Cycle Timing</h5>
							<div class="form-group" v-if="!cycle_running">
								<input type="number" v-model="cycle_period" class="form-control">
								<label>Seconds</label>
							</div>
							<div class="form-group" v-if="cycle_running">
								<div class="input-group">
									<input type="text" readonly class="form-control form-control-readonly" v-model="cycle_count">
									<div class="input-group-append">
										<button type="button" class="btn btn-danger" @click="clear_cycle">
											<i class="bi bi-stop-sign"></i> Stop
										</button>
									</div>
								</div>
								<label>Seconds until next Cycle</label>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<h5>Number of Files</h5>
								<div class="input-group">
									<input type="number" v-model="limit" class="form-control">
									<div class="input-group-append">
										<button type="button" class="btn btn-light" @click="go_click">
											<i class="bi bi-caret-right"></i> Go
										</button>
									</div>
								</div>
								<label>Files per Cycle</label>
							</div>
						</div>
						<div class="col">
							<h5>Status</h5>
							<div v-if="running">
								<div class="spinner-border" role="status"></div> Importing Photos
							</div>
							<div class="badge badge-success" v-if="completed">
								Cycle Complete
							</div>
							<div class="badge badge-danger" v-if="incomplete">
								Failed/Interrupted
							</div>
						</div>
						<div class="col">
							<h5>Results:</h5>
							<div v-if="completed">
								<div>Files Processed: <div class="badge badge-success">{{processed}}</div></div>
								<div>Files Skipped: <div class="badge badge-info">{{skipped}}</div></div>
								<div>Files Failed: <div class="badge badge-danger">{{failed}}</div></div>
							</div>
						</div>
					</div>
					
				</form>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SystemPanel',
	components: [],
	data: function(){ return {
		running: false,
		completed: false,
		incomplete: false,
		processed: 0,
		limit: 10,
		skipped: 0,
		failed: 0,
		cycle_period: 40,
		cycle_timeout: null,
		cycle_running: false,
		cycle_count: 0,
		cycle_count_timeout: null,
		rebuilding_toy_cache: false,
	}},
	methods: {
		go_click: function(){
			this.upload()
			this.start_cycle()
		},
		repair_toy_status: function(){
			let v = this
			v.rebuilding_toy_cache = true
			v.api.get({
				url: '/repair_latest_toy_history',
				dataType: 'text',
				success(){
					v.rebuilding_toy_cache = false
					v.nerivon_alert('Repair Complete','Rebuild of toy caches completed','success')
				},
			})
		},
		rebuild_toy_cache: function(){
			let v = this
			v.rebuilding_toy_cache = true
			v.api.get({
				url: '/rebuild_toy_history_cache',
				dataType: 'text',
				success: function(){
					v.rebuilding_toy_cache = false
				},
				fail: function(x,s,e){
					console.error('Problem rebuilding toy history cache',x,s,e)
				},
			})
		},
		upload: function(){
			let v = this
			if( v.running == true )
				return
			v.running = true
			v.completed = false
			v.api.get({
				url: '/import_photos',
				data: {key: '20898pjasdf089pi2lkhja]0PO]OKJLKJNAO89up',limit:v.limit},
				success: function(response){
					v.running = false
					v.processed = response.files_processed
					v.completed = true
					v.skipped = response.files_skipped
					v.failed = response.files_failed
					if( v.processed == 0 )
						v.clear_cycle()
				},
				fail: function(){
					v.clear_cycle()
					v.running = false
					v.processed = 0
					v.completed = false
					v.skipped = 0
					v.failed = 0
					v.incomplete = true
				},
			})
		},
		start_cycle: function(){
			let v = this
			v.cycle_running = true
			v.cycle_count = 0
			v.cycle_count_timeout = setInterval(function(){ 
				v.cycle_count += 1
				if( v.cycle_period == v.cycle_count )
					v.upload()
				if( v.cycle_count > v.cycle_period )
					v.cycle_count = 0
			},1000)
		},
		clear_cycle: function(){
			let v = this
			v.cycle_running = false
			v.cycle_count = 0
			clearInterval(v.cycle_count_timeout)
			clearTimeout(v.cycle_timeout)
		},
	}
}

</script>