<template>
	<div class="toy">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-box /> Toy: {{record.toy_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<div class="btn-group">
						<button class="btn btn-info dropdown-toggle" type="button" id="dropdownPrintMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i class="bi bi-printer"></i> Print
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownPrintMenuButton">
						
							<button class="dropdown-item" @click="print_qr_labels('')"><i class="bi bi-upc-scan"></i> QR Code Full</button>
							<button class="dropdown-item" @click="print_qr_labels('?size=2')"><i class="bi bi-upc-scan"></i> QR Code Med</button>
							<button class="dropdown-item" @click="print_qr_labels('?size=1')"><i class="bi bi-upc-scan"></i> QR Code Small</button>
							<a class="dropdown-item" href="#"><i class="bi bi-clipboard-data"></i> Toy Details</a>
							<a class="dropdown-item" href="#"><i class="bi bi-journal-text"></i> Toy History</a>
						</div>
					</div>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="loading=1;save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<ul class="nav nav-tabs mb-5">
				<li class="nav-item">
					<a class="nav-link" :class="{active:current_tab==1}" @click.prevent="current_tab=1" href="#">General</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" :class="{active:current_tab==2}" @click.prevent="current_tab=2" href="#">History</a>
				</li>
			</ul>
			<div v-show="current_tab==1">
				<!-- <div class="row">
					<div class="col-md-5">
						<div class="form-group">
							<label>Toy Type</label>
							<div class="input-group">
								<input type="text" class="form-control form-control-plaintext" :value="record.toy_type" readonly>
								<div class="input-group-append">
									<button type="button" class="btn btn-sm btn-outline-info" @click="open_toy_type(record.toy_type_id)">View/Edit</button>
								</div>
							</div>
							<p class="text-muted"><small>Tags are available only for Toy Types.</small></p>
						</div>
					</div>
				</div> -->
				<div class="row">
					<div class="col-md-2">
						<div class="form-group">
							<label for="input-2">Toy Number</label>
							<input id="input-2" class="form-control" v-model="record.toy_number" required>
							<div class="invalid-feedback">Toy Numbers must be provided and unique.</div>
							<div class="text-danger" v-if="toy_number_unique_fail">Provided Toy Number is already in use.</div>
						</div>
					</div>
					<div class="col-md-5">
						<b-form-group id="toy_name" label="Toy Name" label-for="input-1">
							<b-form-input id="input-1" v-model="record.toy_name" required></b-form-input>
						</b-form-group>
					</div>
					<div class="col-md-5">
						<div class="row">
							<div class="col-md-8">
								<div class="text-right mb-2">
									<label class="float-left">Condition</label>
									<div class="badge" :class="condition_class(record.condition)">{{condition_text(record.condition)}}</div>
								</div>
								<div class="text-right mb-2">
									<label class="float-left">Status</label>
									<div class="badge" :class="status_class(record.status)">{{status_text(record.status)}}</div>
								</div>
							</div>
							<div class="col-md-4" v-if="history.length > 0">
								<!-- {{history[0]}} -->
								<StatusConditionChangeDialog :toy_id="record.toy_id" :status="record.status" :condition="record.condition" :interventionist="history[0].interventionist_id" :client="history[0].client_id" :vendor="history[0].vendor_id" @saved="load_toy_item(record.toy_id)" />
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="input-4">Vendor</label>
									<select id="input-4" v-model="record.vendor_id" class="form-control" required>
										<option v-for="v in vendors" :key="v.vendor_id" :value="v.vendor_id">{{v.vendor_name}}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="price">Price</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text">$</div>
										</div>
										<input type="number" id="price" v-model="record.price" class="form-control" required>
									</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="purchase_date">Purchase Date</label>
									<input type="date" id="purchase_date" v-model="record.purchase_date" class="form-control" required>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label for="storage_location">Storage Location</label>
							<input type="text" class="form-control" v-model="record.storage_location">
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3">
						<div class="form-group">
							<label for="category_id">Category</label>
							<select id="category" v-model="record.category_id" class="form-control">
								<option v-for="category in categories" :key="category.category_id" :value="category.category_id">{{category.category_name}}</option>
							</select>
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group" v-if="record.options.author">
							<label for="Author">Author</label>
							<input type="text" class="form-control" v-model="record.author" @input="filterAuthors()" autocomplete="off" @focus="author_modal = true">
							<div v-if="filteredAuthors && author_modal">
								<ul class="list-group">
									<li v-for="filteredAuthor in filteredAuthors" v-bind:key="filteredAuthor" @click="setAuthor(filteredAuthor)" class="list-group-item list-group-item-action">
										{{filteredAuthor}}
									</li>
								</ul>
							</div>							
						</div>
						<div class="form-group" v-if="record.options.brand">
							<label for="Brand">Brand</label>
							<input type="text" class="form-control" v-model="record.brand" autocomplete="off">
						</div>
					</div>


		<!-- <div>
			<input type="text" placeholder="Enter Country name..." v-model="query" @keyup="getData()" autocomplete="off" class="form-control input-lg" />
			<div class="panel-footer" v-if="search_data.length">
				<ul class="list-group">
					<a href="#" class="list-group-item" v-for="data1 in search_data" @click="getName(data1.country_name)">{{ data1.country_name }}</a>
				</ul>
			</div>
		</div> -->
		





					<div class="col-md-6">
						<div class="form-group">
							<label for="tags42">Tags</label>
							<vue-tags-input	id="tags43" v-model="tag" :tags="tags" :autocomplete-items="toptags" @tags-changed="newTags => tags = newTags"  />
							<div class="mt-1">
								<span v-for="t in toptags" :key="t.tag_name" class="text-secondary text-muted" style="word-wrap: break-word;">
									<small><a href="#" @click.prevent="add_tag_by_anchor" class="text-secondary mr-2 clickable">#{{t.tag_name}}</a></small> 
								</span>
							</div>
						</div>
					</div>
				</div>



				<hr />
				<div>
					<fieldset class="p-3 border rounded"><legend><small>Photos</small></legend>
						<div v-if="photos.length">
							<div v-for="photo in photos" :key="photo.toy_photo_id" style="display:inline-block; position: relative;">
								<div class="badge badge-info p-2" style="position: absolute; top: 0px; right: 0px;" v-if="photo.toy_photo_id==record.primary_photo_id">P</div>
								<img :src="photo_url(photo,200)" class="img-thumbnail mr-1" /><br />
								<div class="mt-1">
									<small><a href="#" class="text-muted" @click.prevent="delete_photo(photo.toy_photo_id)">Delete</a></small> |
									<small><a href="#" class="text-muted" @click.prevent="make_primary(photo.toy_photo_id)">Make Primary</a></small>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
				<hr />
				<div class="row">
					<div class="col">
						<div @drop.prevent="addFile" @dragover.prevent class="p-3 border bg-light rounded">
							<!-- <span v-if="uploading">
								<div class="text-center mt-3 mb-3">
									<img src="/img/cloud-upload-trans.gif" />
								</div>
							</span> -->
							<p class="text-center text-muted">Drag New Photos Here to Upload Them</p>



						</div>
					</div>



					<div class="col">
						<h5>Upload a file the old fashioned way...</h5>
						<!-- <form method="POST" action="http://localhost:5000/upload" enctype="multipart/form-data"> -->
							<input class="form-control-input" type="file" name="file" id="file1" @change="selectFile" multiple/>
							<!-- <button type="button" class="btn btn-warning" @click="upload"><b-icon-upload /> Upload</button> -->
							<!-- <button class="btn btn-primary" type="submit">Upload</button> -->
						<!-- </form> -->
					</div>




				</div>
				<div class="row">
					<div class="col">

													<span v-if="uploading">
								<div class="text-center mt-3 mb-3">
									<img src="/img/cloud-upload-trans.gif" />
								</div>
							</span>

								<span v-if="!uploading">
								<!-- <p v-show="files.length==0" class="text-center text-muted">Drag New Photos Here to Upload Them</p> -->
								
								<!-- <p class="text-center text-muted">Drag New Photos Here to Upload Them</p> -->

								<p v-show="files.length" class="text-muted">Press Upload after you've finished added photos:</p>
								<ol>
									<li v-for="file in files" v-bind:key="file.name">
										<div class="row mb-1" style="border-bottom: 1px dotted #999">
											<div class="col">
												{{ file.name }} 
											</div>
											<div class="col text-right">
												({{ file.size | kb }} kb)
												<button class="btn btn-primary btn-sm" @click="removeFile(file)" title="Remove">X</button>
											</div>
										</div>
									</li>
								</ol>
								<button type="button" class="btn btn-warning" @click="upload" v-show="files.length"><b-icon-upload /> Upload</button>
							</span>

					</div>
				</div>

			




			</div><!-- End of General Section -->
			<div v-show="current_tab==2">
				<h2>History</h2>
				<table class="table">
					<thead>
						<tr>
							<th>Date<div><small class="text-muted">Date of the Historical Entry.</small></div></th>
							<th>User<div><small class="text-muted">Person who made the change.</small></div></th>
							<th>Path</th>
							<th>Recipient</th>
							<th>Condition</th>
							<th colspan="2">Expected Return</th>
						</tr>
					</thead>
					<tbody v-for="h in history" :key="h.history_id">
						<tr>
							<td>{{h.entry_date}}</td>
							<td>{{h.user_full_name}}</td>
							<td><div class="badge" :class="status_class(h.type)">{{status_text(h.type)}}</div></td>
							<td>{{h.recipient}}</td>
							<td><div class="badge" :class="condition_class(h.condition)">{{condition_text(h.condition)}}</div></td>
							<td>{{h.return_date}}</td>
							<td>
								<button type="button" class="btn btn-light" @click="delete_history(h.toy_history_id)"><i class="bi bi-x"></i></button>
							</td>
						</tr>
						<tr v-if="h.notes">
							<th>Notes:</th>
							<td colspan="6" class="text-right muted">{{h.notes}}</td>
						</tr>
					</tbody>
				</table>

			</div><!-- End of History Section -->
		</div>
	</div>
</template>

<script>

// import Vue from 'vue'
// https://github.com/JohMun/vue-tags-input
import VueTagsInput from '@johmun/vue-tags-input'

import {settings} from '../../settings.js'
import Vue from 'vue'
import StatusConditionChangeDialog from '../../components/StatusConditionChangeDialog'

export default {
	name: 'ToyItem',
	components: {
		StatusConditionChangeDialog,
		VueTagsInput,
	},
	filters: {
		kb: val => {
			return Math.floor(val/1024);
		},
	},
	data: function(){
		return {
			tag: '',
			tag_input: null,
			tags: [],
			files: [],
			loading: false,
			uploading: false,
			toy_number_unique_fail: false,
			toy_types: [],
			vendors: [],
			toptags: [],
			statuses: {
				'to_library':'Added to Library',
				'to_client':'Given to Client',
				'to_interventionist':'Withdrawn by Consultant',
				'to_retirement':'Retired/Removed from Library',
				'to_vendor_repair':'Sent to Vendor for Repair',
				'to_vendor_return':'Sent to Vendor for Return',
				'to_vendor_cleaning':'Sent to Vendor for Cleaning'
			},
			conditions: {
				'good': 'Good',
				'needs_repair': 'Need Repair',
				'needs_replacement':'Needs Replacement',
				'needs_cleaning':'Needs Cleaning',
				'missing_pieces':'Missing Pieces',
			},
			record: {
				toy_id: 0,
				toy_name: null,
				toy_number: null,
				condition: null,
				status: null,
				price: 0,
				vendor_id: 0,
				purchase_date: null,
				ecc_use_only: 0,
				primary_photo_id: 0,
				storage_location: '',
				author: '',
				category_id: null,
				options: {brand:false,author:false}
			},
			history: [],
			photos: [],
			current_tab: 1,
			newTags: [],
			authors: [],
			filteredAuthors:[],
			categories:[],
			author_modal: false,
		}
	},
	methods: {

		filterAuthors(){

			this.filteredAuthors = this.authors.filter(author =>{

					if(this.record.author.length > 0)
					{
						return author.toString().toLowerCase().includes(this.record.author.toLowerCase());
					}
				
			});

		},

		setAuthor(author){

			this.record.author = author;
			this.author_modal = false;

		},


		delete_history(id){
			let v = this
			alert(id)
			
			v.nerivon_confirm('Delete History?','Are you sure you want to delete this history record?','warning',result=>{
				if( result )
				{
					v.api.delete({
						url: '/admin/history/'+String(id),
						method: 'delete',
						dataType: 'json',
						success: function(){
							v.toast('History Deleted','success')
							v.load_toy_item(v.record.toy_id)
						},
						fail: function(x,s,m){
							let msg = 'History could not be deleted'
							v.toast(msg,'danger')
							console.error(msg,x,s,m)
						}
					})
				}
				else
					v.toast('Cancelled','info')
			})
		},
		status_text(status){
			if(status)
				return this.statuses[status]
			else
				return ''
		},
		status_class(status){
			if(!status)
				return 'badge-light';
			if( status == 'to_library' ){
				return 'badge-success';
			}
			if( status == 'to_interventionist' || status == 'to_client' ){
				return 'badge-warning';
			}
			return 'badge-danger';
		},
		condition_text(condition){
			if(condition)
				return this.conditions[condition]
			else
				return ''
		},
		condition_class(condition){
			if( condition == 'good' ){
				return 'badge-success'
			}
			return 'badge-warning'
		},
		cancel: function(){
			this.loading = true;
			this.$router.push('/admin/toys');
		},
		remove: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Toy Record',
				"Are you certain you wish to remove this Toy from the library completely with ALL of it's history? This operation CANNOT be un-done.",
				'danger',
				function(answer){
					v.loading = false;
					if(answer){
						v.api.delete({
							url: '/admin/toy/'+String(v.record.toy_id),
							success: function(){
								v.toast('Toy Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								v.toast('Toy Deletion Failed','danger');
								console.error('Toy Deletion Failed',x,s,m)
							},
						});
					}
				}
			);
		},
		save: function(prevent_redirect=false, callback=null){
			let v = this;
			
			let data = {
				toy: v.record,
				tags: v.tags,
			}

			v.loading = true;

			v.api.post({
				url: '/admin/toy/'+String(v.record.toy_id),
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				success: function(response){
					v.loading = false;
					if( response.result )
					{
						v.toast('Toy Changes Saved','success');
						if(!prevent_redirect)
							setTimeout(v.cancel,500);
						if(callback != null)
							callback();
					}
					else
					{
						v.toast('Unable to Save Changes, Due to Validation failure','info')
						console.error(response.errors)
						v.toy_number_unique_fail = true;
					}
				},
				fail: function(x,s,m){
					var msg = 'Toy Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load_vendors: function(){
			var v = this;
			v.api.get({
				url: '/vendors',
				success: function(response){
					v.vendors = [];
					for(var i in response){
						var vendor = response[i];
						v.vendors.push(vendor);
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Vendors List';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
		load_toy_item: function(id){
			var v = this;

			if( !id && v.record.toy_id )
				id = v.record.toy_id;
			if( !id && !v.record.toy_id ){
				v.toast('Problem loading toy','danger')
				console.error('Tried to load toy using id: '+String(id))
				return
			}

			this.api.get({
				url: '/admin/toy/'+String(id),
				success: function(response){
					for(var i in response.toy){
						v.record[i] = response.toy[i];
					}
					v.tags = []
					for(let i in response.tags)
					{
						let tag = response.tags[i];
						tag['text'] = tag['tag_name']
						v.tags.push(tag)
					}
					v.history = []
					for(i in response.history){
						v.history.push(response.history[i])
					}
					v.load_photos(v.record.toy_id);
				},
				fail: function(x,s,m){
					var msg = 'Toy Item Failed to Load';
					v.toast(msg,'danger')
					console.error(msg,x,s,m);
				},
			});
		},
		load_photos: function(toy_id){
			let v = this;
			v.api.get({
				url: '/toy_photos',
				data: {toy_id: toy_id},
				success: function(response){
					Vue.set(v,'photos',[]);
					for(let i in response){
						v.photos.push(response[i]);
					}
				},
				fail: function(x,s,m){
					Vue.set(v,'photos',[]);
					let msg = 'Toy Photos Failed to Load';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			})
		},
		load_categories(){
			let v = this;
			v.api.get({
				url: '/categories',
				success: function(response){
					v.categories = []
					for(let i in response){
						let category = response[i]
						v.categories.push(category)
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories List'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_toptags(){
			let v = this
			v.toptags = []
			v.api.get({
				url: '/admin/toptags',
				success: (response)=>{
					for(let i in response){
						let tag = response[i]
						tag.text = tag.tag_name
						v.toptags.push(tag)
					}
				},
				fail: (x,s,m)=>{
					let msg = 'Failed to retrieve Top Tags from server'
					console.error(msg,x,s,m)
					v.toast(msg,'danger')
				},
			})
		},
		load_authors(){
			let v = this
			v.api.get({
				url: '/admin/authors',
				success: (response)=>{
					for(let i in response){
						let author = response[i]
						v.authors.push(author["author"])
					}
				},
				fail: (x,s,m)=>{
					let msg = 'Failed to retrieve Authors from server'
					console.error(msg,x,s,m)
					v.toast(msg,'danger')
				},
			})
		},
		photo_url: function(photo,size){
			let host = settings.api_host;
			let url = host + '/photo/' + String(photo.toy_photo_id) + '?s=' + String(size);
			return url;
		},
		addFile(e) {
			let droppedFiles = e.dataTransfer.files;
			if(!droppedFiles) return;
			// this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
			([...droppedFiles]).forEach(f => {
				// An attempt to make thumbs load as they drop but, this proved really inefficient
				// let reader = new FileReader();
				// reader.onload = function(e){
				// 	f.src = e.target.result;
				// }
				// reader.readAsDataURL(f);
				this.files.push(f);
			});
		},
		removeFile(file){
			this.files = this.files.filter(f => {
				return f != file;
			});
		},
		delete_photo(toy_photo_id){
			let v = this;
			this.api.post({
				url: '/delete_photo',
				data: {toy_photo_id: toy_photo_id},
				success: function(){
					v.load_photos(v.record.toy_id);
				}
			})
		},
		make_primary(toy_photo_id){
			let v = this
			v.record.primary_photo_id = toy_photo_id
		},

		selectFile(event){

			// console.log(event)

			for( let i = 0; i < event.target.files.length; i++)
			{
				let file = event.target.files.item(i)
				this.files.push(file);
			}

			// Depreciated, the files property is now a "FileList" object that doesn't iterate as in this code
			// event.target.files.forEach(element => { 
			// 	this.files.push(element);
			// });
		},


		upload() {

			let v = this;

			v.uploading = true;

			let fd = new FormData();
			this.files.forEach((f,x) => {
				fd.append('file'+(x+1), f);
			});
			fd.append('toy_id',this.record.toy_id);

			this.api.upload({
				url: '/upload_photo', 
				data: fd,
				success: function(response){
					v.uploading = false;
					if(response.result) {
						Vue.set(v,'files',[])
						v.toast('File(s) Uploaded','success');
						v.load_photos(v.record.toy_id)
					}
					else{
						let msg = 'File(s) Upload Failed';
						v.toast(msg,'danger');
						console.error(msg,response);
						Vue.set(v,'files',[])
					}
				},
				fail: function(x,s,m){
					v.uploading = false;
					let msg = 'File(s) Upload Request Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					Vue.set(v,'files',[])
				}
			});
		},
		print_qr_labels(page_size){
			// Retrieve a generated pdf of the label from the server
			let v = this;
			this.save(1,function(){
				window.open(settings.api_host+'/pdf/qrlabel/'+String(v.record.toy_id)+page_size);
			})
		},
		open_toy_type(toy_type_id){
			let v = this;
			v.$router.push('/admin/toy_type/'+String(toy_type_id));
		},

		add_tag_by_anchor: function(event){
			let tag = event.target.text
			tag = tag.replace('#','')
			if(this.tags.length == 0 || this.tags.indexOf(tag) == -1){
				this.tags.push({text:tag})
			}
		},	
	},

	mounted: function(){
		var id = this.$route.params.id;
		this.load_vendors();
		this.load_toy_item(id);
		this.load_categories();
		this.load_toptags();
		this.load_authors();
	},
}
</script>
