<template>
	<div class="loginform container">
		<div class="d-flex justify-content-center mt-5">
			<div class="border border-dark rounded" style="max-width:400px;">
				<div class="pl-4 pr-4 pt-3 pb-1">
					<h3>Login to the Library</h3>
					<p class="muted"><small>If you are new to the library or do not have an account, you must be added by an administrator. Please contact the ECIP admin staff in your area for help.</small></p>
				</div>
				<form class="pl-5 pr-5" @submit.prevent="login">
					<div class="form-group">
						<label for="username">User Name</label>
						<input type="text" class="form-control" v-model="username" required />
					</div>
					<div class="form-group">
						<label for="password">Password</label>
						<input type="password" class="form-control" v-model="password" required />
					</div>
					<div class="form-group text-center">
						<button type="submit" class="btn btn-secondary mt-2">Login</button>
						<p class="mt-3"><a href="/reset-password">Forgot Password?</a></p>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

import Cookies from 'js-cookie'

export default {
	name: 'Login',
	data: function(){
		return {
			username: '',
			password: '',
			token: null,
			msg: null,
			attempts: 0,
		}
	},
	components: {
	},
	methods: {
		login(){
			var v = this;
			v.api.post({
				url: '/login',
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				data: JSON.stringify({
					username:v.username,
					password:v.password,
				}),
				success: function(response){
					v.attempts++
					if( !response.success )
					{
						v.msg = response.msg
						v.toast(v.msg,'danger')
						return false
					}
					else
						v.toast('Login Successful','success')

					Cookies.set('user_id',response.user.user_id,{expires: 7});
					Cookies.set('userfullname',response.user.userfullname,{expires: 7});
					let action_list = []
					for(let a in response.user.actions){
						action_list.push(response.user.actions[a].constant)
					}
					Cookies.set('actions',JSON.stringify(action_list),{expires:7});
					let url = Cookies.get('redirect_page');
					if( url && url.search('/login') == -1 )
					{
						window.location.href = url;
					}
					else
					{
						window.location.href = '/';
					}
				},
				fail: function(x,s,m){
					v.attempts++
					console.error('XHR Failure on Login Attempt',x,s,m)
				},
			});
		},
	},
	mounted: function(){
	},
}
</script>
