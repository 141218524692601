<template>
	<div class="toy_type">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-box /> Toy Type: {{record.toy_type_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-success" :disabled="loading" v-show="record.toy_type_id==0" @click="save(1)"><b-icon-box-arrow-in-down-right /> Save New Toy Type</button>
					<button type="button" class="btn btn-primary" :disabled="loading" v-show="record.toy_type_id>0" @click="save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<a class="nav-link" :class="{active:current_tab==1}" @click="current_tab=1" href="#">General</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" :class="{active:current_tab==2}" @click="current_tab=2" href="#">Custom Labels</a>
				</li>
			</ul>
		</div>
		<div class="container mt-3" v-show="current_tab==1">
			<form class="needs-validation" novalidate>
				<div class="row">
					<div class="col-md-8">
						<div class="form-group">
							<label for="toy_type_name">Toy Type Name</label>
							<input id="toy_type_name" v-model="record.toy_type_name" class="form-control" required>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label for="vendor_id">Preferred Vendor</label>
							<select id="vendor_id" v-model="record.vendor_id" class="form-control">
								<option v-for="v in vendors" :key="v.vendor_id" :value="v.vendor_id">{{v.vendor_name}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="category_id">Category</label>
							<select id="category_id" v-model="record.category_id" class="form-control">
								<option v-for="category in categories" :key="category.category_id" :value="category.category_id">{{category.category_name}}</option>
							</select>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="tags42">Tags</label>
							<vue-tags-input	id="tags43" v-model="tag" :tags="tags" :autocomplete-items="toptags" @tags-changed="newTags => tags = newTags"  />
							<div class="mt-1">
								<span v-for="t in toptags" :key="t.tag_name" class="text-secondary text-muted" style="word-wrap: break-word;">
									<small><a href="#" @click.prevent="add_tag_by_anchor" class="text-secondary mr-2 clickable">#{{t.tag_name}}</a></small> 
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>
			<hr />
			<div class="row" v-if="record.toy_type_id > 0">
				<div class="col">
					<h5>Toy(s) in Inventory</h5>
				</div>
				<div class="col text-right">
					<button type="button" class="btn btn-primary btn-small" @click="add_new_toy">Add New Toy of this Type to Inventory</button>
				</div>
			</div>
		</div>
		<div class="container mt-3" v-show="current_tab==2">
			<p>Custom labels are simply printed out so that you can affix them to "bags" of toys or to the backs of toys that require special instructions of any kind.</p>
			<form class="needs-validation" id="custom-label-form" novalidate>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="custom_label_text">Custom Label</label>
							<textarea class="form-control" v-model="custom_label_text"></textarea>
						</div>
					</div>
					<div class="col-3">
						<div class="btn-group mt-4">
							<button type="button" class="btn btn-light">Clear</button>
							<button type="button" class="btn btn-success">Save</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

// import Vue from 'vue'
// https://github.com/JohMun/vue-tags-input
import VueTagsInput from '@johmun/vue-tags-input'

export default {
	name: 'ToyType',
	components: {
		VueTagsInput,
	},
	data: function(){
		return {
			loading: false,
			current_tab: 1,
			custom_labels: [],
			custom_label_text: '',
			vendors: [],
			categories: [],
			record: {
				toy_type_id: 0,
				toy_type_name: null,
				vendor_id: 0,
				toy_photo_id: 0,
			},
			tag: '',
			tag_input: null,
			tags: [],
			photos: [],
			toptags: [],
			newTags: [],
		}
	},
	methods: {
		add_new_toy: function(){
			this.$router.push('/admin/newtoy/step2/'+String(this.record.toy_type_id))
		},
		cancel: function(){
			this.loading = true
			this.$router.go(-1)
			//this.$router.push('/admin/toy_types')
		},
		remove: function(){
			var v = this
			v.loading = true
			v.nerivon_confirm(
				'Delete Toy Type',
				"Are you sure you wish to remove this Toy Type?",
				'warning',
				function(answer){
					v.loading = false
					if( answer ){
						v.api.delete({
							url: '/admin/toy_type/'+String(v.record.toy_type_id),
							success: function(){
								v.toast('Toy Type Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								v.toast('Toy Type Deletion Failed','danger');
								console.error('Toy Type Deletion Failed',x,s,m)
							},
						});
					}
				});
		},
		save: function(prevent_redirect=false){
			let v = this
			v.loading = true

			let data = {
				record: v.record,
				tags: v.tags
			}

			v.api.post({
				url: '/admin/toy_type/'+String(v.record.toy_type_id),
				data: JSON.stringify(data),
				contentType: 'json',
				success: function(response){
					v.toast('Toy Type Changes Saved','success')
					if( !prevent_redirect )
						setTimeout(v.cancel,500)
					else
						v.load_toy_type(response.toy_type_id)
					v.loading = false
				},
				fail: function(x,s,m){
					v.toast('Toy Type Change Failed','danger')
					console.error('Toy Type Change Failed',x,s,m)
					v.loading = false
				},
			});
		},
		load_toy_type: function(id){
			if(!(id>0))
				return;
			var v = this
			v.tags = []
			v.loading = true
			this.api.get({
				url: '/admin/toy_type/'+String(id),
				success: function(response){
					for(var i in response.toy_type){
						v.record[i] = response.toy_type[i]
					}
					for(let i in response.tags)
					{
						let tag = response.tags[i];
						tag['text'] = tag['tag_name']
						v.tags.push(tag)
					}
					v.loading = false
				},
				fail: function(x,s,m){
					v.toast('ToyType Failed to Load','danger')
					console.error('ToyType Failed to Load',x,s,m)
					v.loading = false
				},
			});
		},
		load_vendors(){
			let v = this
			v.api.get({
				url: '/vendors',
				success: function(response){
					v.vendors = []
					for(var i in response){
						var vendor = response[i]
						v.vendors.push(vendor)
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Vendors List'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			});
		},
		load_categories(){
			let v = this;
			v.api.get({
				url: '/categories',
				success: function(response){
					v.categories = []
					for(let i in response){
						let category = response[i]
						v.categories.push(category)
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories List'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_toptags(){
			let v = this
			v.toptags = []
			v.api.get({
				url: '/admin/toptags',
				success: (response)=>{
					for(let i in response){
						let tag = response[i]
						tag.text = tag.tag_name
						v.toptags.push(tag)
					}
				},
				fail: (x,s,m)=>{
					let msg = 'Failed to retrieve Top Tags from server'
					console.error(msg,x,s,m)
					v.toast(msg,'danger')
				},
			})
		},
		add_tag_by_anchor: function(event){
			let tag = event.target.text
			tag = tag.replace('#','')
			if(this.tags.length == 0 || this.tags.indexOf(tag) == -1){
				this.tags.push({text:tag})
			}
		},
	},
	mounted: function(){
		var id = this.$route.params.id
		this.load_vendors()
		this.load_toptags()
		this.load_categories()
		this.load_toy_type(id)
	},
}
</script>
