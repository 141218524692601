<template>
	<div class="toy_new">
		<div class="bg-success text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1>Add A New Toy to Inventory: Step 1</h1>
				</div>
				<div class="col-md-6 text-right">
					<!-- <button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Toy Type</button> -->
				</div>
			</div>
		</div>
		<div class="container mt-4">
			<h4>Instructions</h4>
			<div class="row mt-4">
				<div class="col-sm-12 col-md-4">
					<p class="text-left">
						<strong>Choose Type of Toy Below...</strong>
					</p>
				</div>
				<div class="col-sm-12 col-md-2 text-center">
					<p>... or ...</p>
				</div>
				<div class="col-sm-12 col-md-6">
					<button type="button" class="btn btn-lg btn-primary btn-block" @click="next">
						<i class="bi bi-plus-circle"></i> Add a Completely New Type of Toy
					</button>
				</div>
			</div>
		</div>
		<ToyTypeList mode="select"></ToyTypeList>
	</div>
</template>

<script>

import ToyTypeList from '@/components/ToyTypeList.vue'

export default {
	name: 'ToyNewStep1',
	components: {
		ToyTypeList,
	},
	data: function(){
		return {}
	},
	methods: {
		next(){
			this.$router.push('/admin/toy_type/0')
		},
	},
	mounted: function(){
	},
}
</script>
