import Cookies from 'js-cookie'

function aclCheck(action_constant,require_all=false){
	let actions = Cookies.get('actions')
	
	if(actions == undefined || actions == '')
		return false;

	actions = JSON.parse(actions)

	// Handle Multiple Constants provided in a comma or space delimited string at the same time
	if( action_constant.search(',') !== -1 || action_constant.search(' ') != -1 ){
		// The user has provided MORE than one constant in a string
		// Break it out and attempt to parse them using an iterative process
		let action_constants = null;
		if( action_constant.search(',') )
			action_constants = action_constant.split(',')
		else if( action_constant.search(' ') )
			action_constants = action_constant.split(' ')
		for( let i in action_constants )
		{
			let result = aclCheck(action_constants[i]);
			if( !require_all && result )
				return true
			if( require_all && result == false )
				return false
		}
		if( require_all )
			return true // Got here out of the loop, all results did not fail
		else
			return false // if i go the whole loop and don't find one that's true if not all are required, then the default result is false
	}

	function check_action(el){
		if( action_constant == el )
			return true
		else
			return false
	}

	return (Array.isArray(actions) && actions.find(check_action) != undefined)
}

export default aclCheck;