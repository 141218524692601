<template>
	<div class="toy-row-horizontal">
		<div class="row mt-5" v-if="toy_loaded">
			<div class="col-3">
				<img class="rounded toythumb" :src="toy.primary_photo_url" :alt="toy.primary_photo_caption" />
			</div>
			<div class="col-9">
				<h4>{{toy.toy_name}} - [{{toy.toy_number}}]</h4>
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-4">
						<div class="form-group">
							<label for="status">Status</label><br />
							<div class="badge" :class="status_class(toy.status)">{{status_text(toy.status)}}</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4">
						<div class="form-group">
							<label for="condition">Condition</label>
							<select id="condition" class="form-control" v-model="toy.condition" @change="$emit('change',toy)">
								<option v-for="(c,idx) in conditions" :key="idx" :value="idx">{{c}}</option>
							</select>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 text-right">
						<button type="button" class="btn btn-sm btn-danger" @click="remove_from_cart(toy.toy_id)"><i class="bi bi-cash"></i> Remove</button>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="notes">Notes on Condition:</label>
							<textarea v-model="toy.note" class="form-control" @keyup="$emit('change',toy)"></textarea>
							<p class="text-muted"><small>Please make note of any damage, missing parts, or other concerns for the library administrator and indicate the condition change above properly.</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	@media screen and ( max-width: 599px ) {
		img.toythumb { width: 55px; }
	}
	@media screen and ( min-width: 600px) {
		img.toythumb { width: 150px;}
	}
</style>

<script>


import Cookies from 'js-cookie'


export default {
	name: 'ToyRowHorizontal',
	props: {
		toy_id: Number,
	},
	data: function(){
		return {
			toy: {},
			toy_loaded: false,
			statuses: {
				'to_library':'Added to Library',
				'to_client':'Given to Client',
				'to_interventionist':'Withdrawn by Consultant',
				'to_retirement':'Retired/Removed from Library',
				'to_vendor_repair':'Sent to Vendor for Repair',
				'to_vendor_return':'Sent to Vendor for Return',
				'to_vendor_cleaning':'Sent to Vendor for Cleaning'
			},
			conditions: {
				'good': 'Good',
				'needs_repair': 'Needs Repair',
				'needs_replacement':'Needs Replacement',
				'needs_cleaning':'Needs Cleaning',
				'missing_pieces':'Missing Pieces',
			},
		}
	},
	methods: {
		load_toy(toy_id){
			let v = this;
			v.toy_loaded = false;
			v.api.get({
				url: '/toy/'+String(v.toy_id),
				success: function(response){
					v.toy = response;
					v.toy.note = '';
					v.toy_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Toy '+String(toy_id);
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			})
		},
		remove_from_cart(toy_id){

			let scanned_toys = [];

			try{
				scanned_toys = JSON.parse(Cookies.get('scanned_toys'));


			}catch(e){
				console.error('JSON parsing error for scanned_toys');
			}

				for (let [i, scanned_id] of scanned_toys.entries()) {

					if (scanned_id == toy_id) {

						scanned_toys.splice(i,1)
					}
				}

				Cookies.set('scanned_toys',scanned_toys)


		},
		status_text(status){
			if(status)
				return this.statuses[status]
			else
				return ''
		},
		status_class(status){
			if(!status)
				return 'badge-light';
			if( status == 'to_library' ){
				return 'badge-success';
			}
			if( status == 'to_interventionist' || status == 'to_client' ){
				return 'badge-warning';
			}
			return 'badge-danger';
		},
		condition_text(condition){
			if(condition)
				return this.conditions[condition]
			else
				return ''
		},
		condition_class(condition){
			if( condition == 'good' ){
				return 'badge-success'
			}
			return 'badge-warning'
		},
	},
	mounted: function(){
		this.load_toy(this.toy_id)
	},
}

</script>