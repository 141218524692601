<template>
	<div class="categories">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Categories</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Category</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<table class="table table-hover table-hover-pointer">
				<thead>
					<tr>
						<th>Category Name</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in categories" v-bind:key="c.category_id" @click="edit(c.category_id)">
						<td>{{c.category_name}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	name: 'CategoryList',
	components: {},
	data: function(){
		return {
			categories: [],
			categories_loaded: false,
		}
	},
	methods: {
		add: function(){
			this.$router.push('/admin/category/0')
		},
		edit: function(id){ //eslint-disable-line
			this.$router.push('/admin/category/'+String(id))
		},
		load: function(){
			var v = this;
			this.api.get({
				url: '/admin/categories',
				success: function(response){
					v.categories = response;
					v.categories_loaded = true;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
	},
	mounted: function(){
		this.load()
	},
}
</script>
