<template>
	<div> <!--toygrid-->
		<div class="row p-0 m-0">
			<div class="col-6 col-md-3 col-lg-3 p-1 border" v-for="(toy,index) in toys" :key="index">
				<div class="" style="width:100%;">
					<div class="badge" :class="status_class(toy.status)" style="position:absolute; left: 4px; top: 4px;">
						{{status_options[toy.status]}}
					</div>
					<div class="badge badge-secondary" style="position: absolute; top: 4px; right: 4px;" v-if="toy.on_picklist">
						Picked
					</div>
					<a :href="toy.primary_photo_url" target="_blank">
						<img :src="toy.primary_photo_url+'?s='+thumbnail_size" :alt="toy.primary_photo_caption" class="rounded" style="min-height:200px;">						
					</a>
					<div class="text-center mt-2">
						{{toy.toy_name}}
					</div>
					<div class="text-center mt-2 mb-3">
						<button type="button" class="btn btn-sm btn-info" @click="add_click(toy.toy_id)"><i class="bi bi-plus-circle"></i> Add to My Pick List</button>
					</div>
					<div class="text-center" v-if="toy.interventionist_full_name">
						<small>
							Checked out by {{toy.interventionist_full_name}}
							on {{toy.last_history_date}}
						</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {settings} from '@/settings.js'

	export default{
		name: 'ToyGridAdvanced',
		props: {
			toys: Array,
		},
		data(){return{
			loading: false,
			empty: false,
			thumbnail_size: 200,
			status_options: {
				'to_library':'In Library',
				'to_interventionist':'With Consultant',
				'to_client':'With Client',
				'to_vendor_repair':'Out for Repair',
				'to_vendor_return':'Returned to Vendor',
				'to_vendor_cleaning':'Out for Cleaning',
			},
			mysettings: settings,
		}},
		methods: {
			status_class(status)
			{
				let classlist = {}
				if(status == 'to_library')
					classlist['badge-success'] = true
				else if(status == 'to_vendor_return')
					classlist['badge-danger'] = true
				else if(status == 'to_client' )
					classlist['badge-info'] = true
				else
					classlist['badge-warning'] = true
				return classlist
			},
			add_click(toy_id){
				for(let idx in this.toys)
				{
					if( this.toys[idx].toy_id == toy_id )
					{
						this.toys[idx].on_picklist = 1
						break;
					}
				}
				this.$emit('add',toy_id)
			},
		},
		mounted(){
			if(this.isMobile())
			{
				this.thumbnail_size = 75;
			}
		},
	}
</script> 
