import Vue from 'vue'
import VueRouter from 'vue-router'
import Toys from '../views/Toys.vue' // TO BE DEPRECATED
import ToyBrowser from '../views/ToyBrowser.vue'
import Scan from '../views/Scan.vue'
import Accounts from '../views/admin/Accounts.vue'
import Account from '../views/admin/Account.vue'
import Users from '../views/admin/Users.vue'
import User from '../views/admin/User.vue'
import ToyTypes from '../views/admin/ToyTypes.vue'
import ToyType from '../views/admin/ToyType.vue'
import ToyList from '../views/admin/ToyList.vue'
import ToyItem from '../views/admin/ToyItem.vue'
import ToyView from '../views/ToyView.vue'
import VendorList from '../views/admin/VendorList.vue'
import Vendor from '../views/admin/Vendor.vue'
import TagList from '../views/admin/TagList.vue'
import Tag from '../views/admin/Tag.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import RoleList from '../views/admin/RoleList.vue'
import Role from '../views/admin/Role.vue'
import Picks from '../views/Picks.vue'
import ToyNewStep1 from '../views/admin/ToyNewStep1.vue'
import ToyNewStep2 from '../views/admin/ToyNewStep2.vue'
import CategoryList from '../views/admin/CategoryList.vue'
import Category from '../views/admin/Category.vue'
import Clients from '../views/admin/Clients.vue'
import Client from '../views/admin/Client.vue'
import SystemPanel from '../views/admin/SystemPanel.vue'
import CheckOut from '../views/CheckOut.vue'
import MyToys from '../views/MyToys.vue'
import CheckIn from '../views/CheckIn.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/',                   name: 'Browse',       component: ToyBrowser },
    { path: '/home',               name: 'Toys2',        component: Toys },
    { path: '/toys',               name: 'Toys3',        component: Toys },
    { path: '/browse',             name: 'Browse',       component: ToyBrowser },
    { path: '/reset-password/',    name: 'ResetPassword',component: ResetPassword },
    { path: '/reset-password/:token', name: 'ResetPassword2', component: ResetPassword },
    { path: '/picks',              name: 'Picks',        component: Picks },
    { path: '/login',              name: 'Login',        component: Login },
    { path: '/scan',               name: 'Scan',         component: Scan },
    { path: '/checkout',           name: 'CheckOut',     component: CheckOut },
    { path: '/checkin',            name: 'CheckIn',      component: CheckIn },
    { path: '/mytoys',             name: 'MyToys',       component: MyToys },
    { path: '/about',              name: 'About',        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') },
    { path: '/admin/accounts',     name: 'Accounts',     component: Accounts },
    { path: '/admin/account/:id',  name: 'Account',      component: Account },
    { path: '/admin/users',        name: 'Users',        component: Users },
    { path: '/admin/user/:id',     name: 'User',         component: User },
    { path: '/admin/roles',        name: 'Roles',        component: RoleList },
    { path: '/admin/role/:id',     name: 'Role',         component: Role },
    { path: '/admin/clients',      name: 'Clients',      component: Clients },
    { path: '/admin/client/:id',   name: 'Client',       component: Client },
    { path: '/admin/toy_types',    name: 'ToyTypes',     component: ToyTypes },
    { path: '/admin/toy_type/:id', name: 'ToyType',      component: ToyType },
    { path: '/admin/toys',         name: 'ToyList',      component: ToyList },
    { path: '/admin/toy/:id',      name: 'ToyItem',      component: ToyItem },
    { path: '/toy/:id',            name: 'ToyView',      component: ToyView },
    { path: '/admin/newtoy/step1', name: 'ToyNewStep1',  component: ToyNewStep1 },
    { path: '/admin/newtoy/step2/:id', name: 'ToyNewStep2', component: ToyNewStep2 },
    { path: '/admin/newtoy',       name: 'ToyNewStep22', component: ToyNewStep2 },
    { path: '/admin/addtoy',       name: 'ToyTypes2',    component: ToyTypes },
    { path: '/admin/vendors',      name: 'VendorList',   component: VendorList },
    { path: '/admin/vendor/:id',   name: 'Vendor',       component: Vendor },
    { path: '/admin/categories',   name: 'CategoryList', component: CategoryList },
    { path: '/admin/category/:id', name: 'Category',     component: Category },
    { path: '/admin/tags',         name: 'TagList',      component: TagList },
    { path: '/admin/tag/:id',      name: 'Tag',          component: Tag },
    { path: '/admin/syspanel',     name: 'SystemPanel',  component: SystemPanel }
];


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
