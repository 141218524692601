<template>
	<div id="toybrowser" class="container pt-3">
		<div class="row">
			<div class="col-md-3">
				<ToyFilters @filter="load_toys" />
			</div>
			<div class="col-9 text-center" v-show="toys.length==0 && !loading">
				<div class="alert alert-warning">There are no toys that match your filter selections. Please adjust your criteria and try again.</div>
			</div>
			<div class="col-9" v-show="loading">
				<div class="text-center">
					<div class="alert alert-info">Loading</div>
					<img src="/img/loading.gif" />
				</div>
			</div>
			<div class="col-md-9" v-if="!loading && toys.length > 0">
				<ToyGridAdvanced ref="toygrid" :toys="toys" @add="add_to_picklist" />
			</div>
		</div>
	</div>
</template>
<script>
	import ToyGridAdvanced from '@/components/ToyGridAdvanced.vue'
	import ToyFilters from '@/components/ToyFilters.vue'
	import {EventBus} from '../main'

	export default{
		name: 'ToyBrowser',
		components: {
			ToyGridAdvanced,
			ToyFilters,
		},
		data(){return{
			empty: false,
			loading: true,
			toys: [],
		}},
		methods: {
			add_to_picklist(toy_id){
				let v = this
				let data = {toy_id:toy_id}
				v.api.post({
					url: '/admin/picklist/'+String(toy_id),
					data: JSON.stringify(data),
					dataType: 'json',
					contentType: 'json',
					success: function(){
						EventBus.$emit('cart')
						v.toast('Added to Picklist','success')
					},
					fail: function(x,s,m){
						console.error(x,s,m)
					},
				})
			},
			load_toys(filters){
				// Temporary, filters should be an object structure from the ToyFilters vue
				let v = this
				let data = {}
				this.loading = true

				if( filters == undefined )
					data = {categories: null,tags: null,keyword: null,possession: null}
				else
					data = {
						categories: filters.categories,
						tags: filters.tags,
						keyword: filters.keyword,
						possession: filters.possession,
					}

				v.api.post({
					url: '/toys',
					data: JSON.stringify(data),
					contentType: 'json',
					success(response){
						v.loading = false
						v.toys = response
						v.empty = (v.toys.length==0)
					},
					fail(){
						v.loading = false
						v.empty = true
						v.toys = []
						alert('failed to load toys')
					},
				})
				return filters
			},
		},
		mounted(){
			this.load_toys()
		},
	} 
</script>
