<template>
	<div id="app">
		<AppNav/>
		<router-view/>
		<div style="height: 200px;"></div>
		<footer class="footer1 bg-dark">
			<div class="m-2">
				ECIP Toy Library
			</div>
		</footer>
	</div>
</template>

<style lang="scss">
	.footer1{
		display: block;
		position: fixed;
		height: 75px;
		bottom: 0px;
		width: 100%;
		color: #fff;
		z-index: 10;
	}
	#app,body,html{
		background-color: #eef1f3;
	}
	img.rounded
	{
		width: 100%;
		max-width: 100%;
		height: auto;
		border-radius: 5px;
	}
</style>
<!-- <style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style> -->

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import Cookies from 'js-cookie'

export default {
	name: 'App',
	components: {AppNav},
	data() {
		return {
			logged_in: (Cookies.get("access_token_cookie") != ""),
			acl: [],
			interval: null,
			public_urls: [
				/^\/login$/
			]
		}
	},
	methods: {
		checkSession: function()
		{
			var vm = this;

			vm.api.get({
				url: '/authenticate',
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				success: function(response){
					let action_list = []
					for(let a in response.user.actions){
						action_list.push(response.user.actions[a].constant)
					}
					Cookies.set('actions',JSON.stringify(action_list),{expires:7});
				}
			});
		},
		checkPath: function(path)
		{
			var vm = this;

			// Don't check security on public URLs.
			for(var i=0; i<vm.public_urls.length; i++)
			{
				if(path.match(vm.public_urls[i]) !== null)
				{
					return;
				}
			}

			vm.checkSession();

			if(vm.interval != null)
			{
				clearInterval(vm.interval);
			}

			vm.interval = setInterval(function()
			{
				vm.checkSession();
			}, 60000);
		}
	},
	mounted: function(){
		this.checkPath(window.location.pathname);
		let cart = Cookies.get('cart',-1);
		if( cart == -1 )
			Cookies.set('cart','[]'); // Set an Empty Cart var
	},
	watch:
	{
		'$route' () {
			this.checkPath(this.$route.path);
		}
	}
}
</script>