<template>
	<div class="passwordresetform container">
		<div class="d-flex justify-content-center mt-5">
			<div class="border border-dark rounded" style="max-width:400px;">
				<div>
					<div v-if="step==0">
						<div class="pl-4 pr-4 pt-3 pb-1">
							<h3>Reset Your Password</h3>
							<p class="muted"><small>Enter the e-mail address associated with your account and we’ll send you a link to reset your password.</small></p>
						</div>
						<form class="pl-5 pr-5">
							<div class="form-group">
								<label for="email">E-mail Address</label>
								<input type="text" class="form-control" v-model="email" />
							</div>
							<div class="form-group text-center">
								<button type="button" class="btn btn-secondary" @click="createToken">Reset Password</button>
							</div>
						</form>
					</div>
					<div v-if="step==1">
						<div class="pl-4 pr-4 pt-3 pb-1 mb-0 alert alert-success">
							<p class="muted"><small>We've e-mailed a link to {{email}}. If you do not receive an e-mail, please verify that the e-mail address you've entered is associated with a valid ECIP account and re-submit it.</small></p>
						</div>
					</div>
					<div v-if="step==2">
						<div class="pl-4 pr-4 pt-3 pb-1">
							<h3>Reset Your Password</h3>
							<p class="muted"><small>Enter a new password for your account.</small></p>
						</div>
						<form class="pl-5 pr-5">
							<div class="form-group">
								<label for="email">New Password</label>
								<input type="password" class="form-control" v-model="password" />
							</div>
							<div class="form-group">
								<label for="email">Confirm Password</label>
								<input type="password" class="form-control" v-model="password2" />
							</div>
							<div class="form-group text-center">
								<button type="button" class="btn btn-secondary" @click="resetPassword">Reset Password</button>
							</div>
						</form>
					</div>
					<div v-if="step==3">
						<div class="pl-4 pr-4 pt-3 pb-1 mb-0 alert alert-success text-center">
							<p class="muted"><small>Your password has been successfully changed.</small></p>
							<p class="muted"><small>Please <a href="/login">log in</a> with your new password.</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: 'ResetPassword',
	data: function()
	{
		return {
			step: 0,
			email: '',
			token: null,
			password: '',
			password2: '',
		}
	},
	components:
	{
	},
	methods:
	{
		createToken()
		{
			var v = this;
			v.api.post({
				url: '/passwordreset',
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				data: JSON.stringify({
					email:v.email,
				}),
				success: function()
				{
					// console.log(response);

					v.step = 1;
				},
				fail: function(x,s,m)
				{
					console.error('Failed to create password reset token',x,s,m);
				},
			});
		},
		resetPassword()
		{
			var v = this;
			v.api.post({
				url: '/resetpassword',
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				data: JSON.stringify({
					token:v.token,
					password:v.password,
					password2:v.password2,
				}),
				success: function()
				{
					// console.log(response);

					v.step = 3;
				},
				fail: function(x,s,m)
				{
					console.error('Failed to reset password',x,s,m);
				},
			});
		},
	},
	mounted: function()
	{
		var v = this;

		v.token = /[^/]*$/.exec(window.location.href)[0];

		// console.log(v.token);

		if (v.token != null && v.token != "" && v.token != "reset-password")
		{
			v.step = 2;
		}
	},
}
</script>
