<template>
	<div class="roles">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Roles</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Role</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<table class="table table-hover table-hover-pointer">
				<thead>
					<tr>
						<th>Role Name</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="u in roles" v-bind:key="u.role_id" @click="edit(u.acl_role_id)">
						<td>{{u.role}}</td>
						<td>{{u.description}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	name: 'RoleList',
	components: {},
	data: function(){
		return {
			roles: [],
			roles_loaded: false,
		}
	},
	methods: {
		add: function(){
			this.$router.push('/admin/role/0');
		},
		edit: function(id){
			this.$router.push('/admin/role/'+String(id));
		},
		load: function(){
			var v = this;
			this.api.get({
				url: '/admin/roles',
				success: function(response){
					v.roles = response;
					v.roles_loaded = true;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
	},
	mounted: function(){
		this.load()
	},
}
</script>
