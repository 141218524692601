<template>
	<div id="toyfilters">
		<div v-show="!show_filters" v-if="isMobile()">
			<button type="button" class="btn btn-block btn-dark" @click="show_filters=true"><i class="bi bi-filter"></i> Show Filters</button>
		</div>
		<div v-show="show_filters">
			<button v-if="isMobile()" type="button" class="btn btn-block btn-dark" @click="show_filters=false"><i class="bi bi-filter"></i> Hide Filters</button>
			<div class="d-flex justify-content-between">
				<strong>Filters</strong>
				<button type="button" class="btn btn-sm btn-warning" @click="reset_filters();apply_filters(100);" title="Clear Filters"><i class="bi bi-x-circle"></i> Reset</button>
			</div>
			<hr />
			<div class="form-group">
				<div class="input-group">
					<input type="text" id="keyword" placeholder="by keyword" class="form-control" v-model="filters.keyword" @keyup="apply_filters(500)" required>
					<div class="input-group-append">
						<button type="button" class="btn btn-sm btn-success" title="Apply Keyword Filter" @click="apply_filters(100)"><i class="bi bi-search"></i></button>
					</div>
				</div>
			</div>
			<hr />
			<strong>Status:</strong>
			<div class="custom-control custom-radio" v-for="opt in options.possession" :key="opt.value">
				<input class="custom-control-input" type="radio" :value="opt.value" :id="'status_'+opt.value" v-model="filters.possession" @click="apply_filters(100)">
				<label class="custom-control-label" :for="'status_'+opt.value">{{opt.text}}</label>
			</div>
			<hr />
			<strong>Categories</strong>
			<div class="custom-control custom-checkbox" v-for="c in options.categories" :key="c.category_name">
				<input class="custom-control-input" type="checkbox" v-model="filters.categories" :value="c.category_id" :id="'categorychk_'+c.category_id" @click="apply_filters(1000)">
				<label class="custom-control-label" :for="'categorychk_'+c.category_id">{{c.category_name}}</label>
			</div>
			<hr />
			<strong>Tags</strong>
			<div class="custom-control custom-checkbox" v-for="tag in options.tags" :key="tag.tag_id">
				<input class="custom-control-input" type="checkbox" v-model="filters.tags" :value="tag.tag_id" :id="'tagchk_'+tag.tag_id" @click="apply_filters(1000)">
				<label class="custom-control-label" :for="'tagchk_'+tag.tag_id">{{tag.tag_name}}</label>
			</div>
		</div>
	</div>
</template>
<script>
	import Cookies from 'js-cookie'

	export default{
		name: 'ToyFilters',
		data(){return {
			show_filters: true,
			filters: {
				keyword: null,
				possession: 'all',
				categories: [],
				tags: [],
			},
			filter_timeout: null,
			options: {
				tags: [],
				categories: [],
				possession: [
					{ text: 'All Toys', value: 'all' },
					{ text: 'In Stock', value: 'in-library'},
					{ text: 'Out with Staff', value: 'with-staff'},
					{ text: 'Out with Clients', value: 'with-client'},
				],
			},
		}},
		methods: {
			apply_filters(timeout_in=500){
				// MAIN ACTION - Filters the list and refreshes the display
				if( this.filter_timeout )
					clearTimeout(this.filter_timeout)

				let v = this
				this.filter_timeout = setTimeout(function(){
					v.$emit('filter',v.filters)
					Cookies.set('toy-filters',JSON.stringify(v.filters))
				},timeout_in)
			},
			reset_filters(){
				if( this._reset == undefined )
				{
					this._reset = {}
					for(let p in this.filters)
						this._reset[p] = this.filters[p]
				}
				for(let p in this._reset)
					this.filters[p] = this._reset[p]
				this.$emit('reset')
				Cookies.set('toy-filters',JSON.stringify(this.filters))
			},
			load_tags(){
				let v = this;
				this.api.get({
					url: '/admin/toptags',
					success: function(response){
						v.options.tags = []
						if(response.length)
						{
							for(let i in response){
								let row = response[i]
								v.options.tags.push(row)
							}
						}
					},
					fail: function(x,s,m){
						var msg = 'Failed to Load Tags'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
					}
				})
			},
			load_categories(){
				let v = this;
				this.api.get({
					url: '/admin/categories',
					success: function(response){
						v.options.categories = []
						if(response.length)
						{
							for(let i in response)
							{
								let category = response[i]
								v.options.categories.push(category)
							}
						}
					},
					fail: function(x,s,m){
						let msg = 'Failed to Load Categories'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
					},
				});
			},
		},
		mounted(){
			if(this.isMobile())
				this.show_filters = false 
			this.load_categories()
			this.load_tags()
			this.reset_filters()
			// let toy_filter_str = Cookies.get('toy-filters')
			// if( toy_filter_str )
			// {
			// 	let data = JSON.parse(toy_filter_str)
			// }
		},
	}
</script> 
