<template>
	<div class="toylist">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-box /> Toy Inventory</h1>
				</div>

				<div class="col-md-6 text-right">

						<button class="btn btn-info" type="button" id="dropdownPrintMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="download_report">
							<i class="bi bi-file-arrow-down"></i> Report
						</button> &nbsp;


					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Toy</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<b-row>
				<b-col sm="12" md="3">
					<strong>Filters</strong>
					<hr />
					<div class="form-group">
						<div class="input-group">
							<input type="text" id="keyword" placeholder="by keyword" class="form-control" v-model="search_keyword" @keyup="evt_filter_click">
							<div class="input-group-append">
								<button type="button" class="btn btn-sm btn-info" @click="search_keyword='';evt_filter_click();"><i class="bi bi-x-circle"></i></button>
							</div>
						</div>
					</div>
					<hr />
					<strong>Categories</strong>
					<div class="form-check" v-for="c in categories" :key="c.category_name">
						<input class="form-check-input" type="checkbox" v-model="search_categories" :value="c.category_id" :id="'categorychk_'+c.category_id" @click="evt_filter_click">
						<label class="form-check-label" :for="'categorychk_'+c.category_id">{{c.category_name}}</label>
					</div>
					<hr />
					<strong>Status</strong>
					<div class="form-check" v-for="(caption,index) in statuses" :key="index">
						<input class="form-check-input" type="checkbox" v-model="search_statuses" :value="index" :id="'statchk_'+index" @click="evt_filter_click">
						<label class="form-check-label" :for="'statchk_'+index">{{caption}}</label>
					</div>
					<hr />
					<strong>Condition</strong>
					<div class="form-check" v-for="(caption,index) in conditions" :key="index">
						<input class="form-check-input" type="checkbox" v-model="search_conditions" :value="index" :id="'condchk_'+index" @click="evt_filter_click">
						<label class="form-check-label" :for="'condchk_'+index">{{caption}}</label>
					</div>
					<hr />
					<strong>Tags</strong>
					<div class="form-check" v-for="tag in tags" :key="tag.tag_id">
						<input class="form-check-input" type="checkbox" v-model="search_tags" :value="tag.tag_id" :id="'tagchk_'+tag.tag_id" @click="evt_filter_click">
						<label class="form-check-label" :for="'tagchk_'+tag.tag_id">{{tag.tag_name}}</label>
					</div>
				</b-col>
				<b-col>
					<table class="table table-hover table-hover-pointer">
						<thead>
							<tr>
								<th>Toys Name/ID</th>
								<th>Category</th>
								<th>Status</th>
								<th>Last Update</th>
								<th>Condition</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="t in toys" v-bind:key="t.toy_id" @click="edit(t.toy_id)">
								<td>
									<div>{{t.toy_name}}</div>
									<div><small class="text-muted">{{t.toy_number}} {{t.author}}</small></div>
									<!-- <div><small class="text-muted">{{t.author}}</small></div> -->
								</td>
								<td>{{t.category_name}}</td>
								<td>
									<div class="badge" :class="get_status_class(t.status)">
										{{statuses[t.status]}}
									</div>
								</td>
								<td>{{t.purchase_date}}*</td>
								<td>
									<div class="badge" :class="get_condition_class(t.condition)">
										{{conditions[t.condition]}}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>



export default {
	name: 'ToyList',
	components: {},
	data: function(){
		return {
			filter_timeout: null,
			search_keyword: null,
			search_statuses: [],
			search_conditions: [],
			search_tags: [],
			search_categories: [],
			categories: [],
			tags: [],
			conditions: {
				'good':'Good',
				'needs_repair':'Needs Repair',
				'needs_replacement':'Needs Replacement',
				'needs_cleaning':'Needs Cleaning',
				'missing_pieces':'Missing Pieces'
			},
			statuses: {
				'to_library':'Added to Library',
				'to_client':'Given to Client',
				'to_interventionist':'Withdrawn by Consultant',
				'to_retirement':'Retired/Removed from Library',
				'to_vendor_repair':'Sent to Vendor for Repair',
				'to_vendor_return':'Sent to Vendor for Return',
				'to_vendor_cleaning':'Sent to Vendor for Cleaning'
			},
			toys: [],
		}
	},
	methods: {

		get_condition_class(condition){
			let c = 'badge-light'
			switch(condition){
				case 'good':
					c = 'badge-light'
					break
				case 'needs_cleaning':
					c = 'badge-warning'
					break
				case 'needs_replacement':
				case 'needs_repair':
				case 'missing_pieces':
					c = 'badge-danger'
					break
			}
			return c
		},
		get_status_class(status){
			let c = 'badge-info'
			switch(status){
				case 'to_library':
					c = 'badge-light'
					break
				case 'to_retirement':
					c = 'badge-secondary'
			}
			return c
		},
		add: function(){
			this.$router.push('/admin/newtoy');
		},
		edit: function(id){
			this.$router.push('/admin/toy/'+String(id));
		},
		load: function(){
			let v = this;
			let data = {
					statuses: v.search_statuses,
					conditions: v.search_conditions,
					keyword: v.search_keyword,
					tags: v.search_tags,
					categories: v.search_categories,
				}
			this.api.post({
				url: '/admin/toys',
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				success: function(response){
					v.toys = response;
				},
				fail: function(x,s,m){
					var msg = "Failed to Load Toys";
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
		load_tags(){
			let v = this;
			this.api.get({
				url: '/admin/toptags',
				success: function(response){
					v.tags = []
					if(response.length)
					{
						for(let i in response){
							let row = response[i]
							v.tags.push(row)
						}
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Tags'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				}
			})
		},
		load_categories(){
			let v = this;
			this.api.get({
				url: '/admin/categories',
				success: function(response){
					v.categories = []
					if(response.length)
					{
						for(let i in response)
						{
							let category = response[i]
							v.categories.push(category)
						}
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},


		download_report(){
			let v = this;
			this.api.get({
				url: '/admin/toyreport',
				success: function(response){
					
					var a = document.createElement("a");
					var file = new Blob([response], {type: "csv"});
					a.href = URL.createObjectURL(file);
					let time = Date.now()
					a.download = `toyreport_${time}.csv`;
					a.click();

					let msg = 'Report downloaded'
					v.toast(msg,'success')


				},
				fail: function(x,s,m){
					let msg = 'Failed to download'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})

		},




		evt_filter_click(){
			let v = this;
			setTimeout(v.load,300);
		},
	},
	mounted: function(){
		this.load_tags()
		this.load_categories()
		this.load()
	},
}
</script>
