<template>
	<div id="checkout" class="container mt-5">
		<h2>Checkout Toys From The Libary</h2>
		<div class="badge badge-info" role="button" v-if="show_instructions===false" @click="toggle_instructions">Need Help? Tap here.</div>
		<div v-if="show_instructions===true">
			<div class="border rounded p-3 bg-light">
				<h5>
					<i class="bi bi-question-circle"></i> Checkout Instructions 
					<div class="badge badge-secondary float-right" @click="toggle_instructions" role="button">Close</div>
				</h5>
				<ul>
					<li>Checking out Toys always moves them from the Library to the My Toys list.</li>
					<li>Toys in the "My Toys" list are toys which <strong>you are responsible</strong> for and considered to be held by you or your clients.</li>
					<li>Removing Toys from the library requires that you physically Scan the Item.</li>
					<li>Items on your Pick List will <i>Disappear</i> as you scan them, which will confirm them into your inventory.</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-8">
				<h3 class="mt-5">My Picklist Toys</h3>
				<hr />
				<div v-if="picklist.length>0">
					<div v-for="toy in picklist" :key="toy.pick_list_id">
						<ToyCartItemAdvanced :toy_id="toy.toy_id" />
					</div>
				</div>
				<div v-if="picklist.length==0" class="text-center">
					<p>There are no toys in your pick list.</p>
				</div>
			</div>
			<div class="col-md-4">
				<ScanPanel @scan="add_scanned_toy" v-show="toy_number_index.length>0" />
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-md-8">
				<h3>Check Out Line</h3>
				<p class="text-muted">Toys that appear in this list have not been <strong>checked out</strong> until you click the Check Out button the bottom of the screen.</p>
			</div>
			<div class="col-md-4">
				<label>Current Staff Member</label><br />
				<input class="form-control form-control-text" v-model="current_user_fullname" readonly>
			</div>
		</div>

		<hr />
		<div v-if="cart.length == 0">
			<p class="text-center">There are no toys in your checkout.</p>
			<p class="text-center">Scan or Enter some Toy Numbers Above to add toys to your checkout.</p>
		</div>
		<div v-if="cart.length > 0">
			<div v-for="toy_id in cart" :key="toy_id">
				<ToyCartItemAdvanced :toy_id="toy_id" />
			</div>
		</div>
		<div class="text-right" v-if="cart.length > 0">
			<hr />
			<button type="button" class="btn btn-success btn-lg" @click="confirm_checkout"><i class="bi bi-cash"></i> Confirm Check Out</button>
		</div>
	</div>
</template>
<script>
	import Cookies from 'js-cookie'
	import ScanPanel from '@/components/ScanPanel.vue'
	import ToyCartItemAdvanced from '@/components/ToyCartItemAdvanced.vue'
	import {EventBus} from '../main'

	export default {
		name: 'CheckOut',
		em: '#checkout',
		components: { 
			ToyCartItemAdvanced, 
			ScanPanel 
		},
		data(){
			return {
				show_instructions: null,
				picklist: [],
				cart: [],
				toy_number_index: [],
				current_user_fullname: null,
			}
		},
		methods: {
			toggle_instructions(){
				this.show_instructions = !this.show_instructions
				Cookies.set('checkout_show_instructions',this.show_instructions)
			},
			add_scanned_toy(scanned_text){
				// Toy Number will be the user assigned number for the toy, like "S210B" or some such.
				// Have to lookup the toy by number first and then pass on the toy ID
				let toy_id = false
				let toy_status = 'to_library'

				for(let index of this.toy_number_index)
				{
					if( String(index.toy_number).toLowerCase() == String(scanned_text).toLowerCase() )
					{
						toy_id = index.toy_id
						toy_status = index.status
						break;
					}
				}

				if( toy_status != 'to_library' )
				{
					this.nerivon_alert('Unavailable','Toy is not available in the library and will not be added to your cart.','warning')
					return
				}

				if( toy_id === false )
				{
					window.alert('Bad Toy Number, '+String(scanned_text)+' was not found in the index.')
					return
				}

				// If the toy is a picklist item, remove it from the picklist
				if( this.picklist.length )
				{
					let index = -1
					for(let i in this.picklist)
					{
						let pick = this.picklist[i]
						if( pick.toy_id == toy_id )
						{
							index = i
							break
						}
					}
					if( index > -1 )
						this.picklist.splice(index,1)
				}

				// Add the New Toy ID to the Cart Items List
				// Prevent Duplicates by not adding it twice if it's already present
				if( this.cart.length )
				{
					for(let cart_toy_id of this.cart)
					{
						if( cart_toy_id == toy_id )
							return // Just end the function, don't push to cart
					}
				}
				this.cart.push(toy_id)
			},
			confirm_checkout(){
				// TODO: Move toys in the cart into MY INVENTORY 
				// TODO: Reset the Picklist on the server side to match whatever is left
				// ENSURE that the toys DO move into the My Toys list and that they are getting the right history changes
				let v = this
				v.api.post({
					url: '/checkout2',
					data: JSON.stringify(v.cart),
					dataType: 'json',
					contentType: 'json',
					success(response){
						if( response.result == true )
						{
							v.cart = [] // clear the cart
							EventBus.$emit('cart')
							EventBus.$emit('mytoys')
							v.nerivon_alert('Checkout Complete','Your items are now in your toy list.','info')
						}
					},
				})
			},
			load_picks(){
				let v = this
				v.api.get({
					url: '/admin/picklist',
					success: function(response){
						v.picklist = response
					},
					fail: function(x,s,m){
						let msg = 'Unable to load user picks'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
					},
				})
			},
			load_scanned_toys(){
				let scanned_toys = [];
				try{
					if(typeof Cookies.get('scanned_toys') == "string")
					{
						scanned_toys = JSON.parse(Cookies.get('scanned_toys'));
					}
				}catch(e){
					console.error('JSON parsing error for scanned_toys');
				}
				if( Array.isArray(scanned_toys) && scanned_toys.length >= 1 )
					this.$set(this,'cart',scanned_toys);
			},
			load_toy_number_index(){
				let v = this
				v.api.get({
					url: '/toy_number_index',
					success: function(response){
						v.toy_number_index = response
					},
				})
			}
		},
		mounted(){
			let s = Cookies.get('checkout_show_instructions')
			this.show_instructions = (s == "true")
			this.current_user_fullname = Cookies.get('userfullname')
			this.load_toy_number_index()
			this.load_picks()
			this.load_scanned_toys()
		},
	}
</script>