<template>
	<div class="toy_new">
		<div class="bg-success text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1>Add A New Toy to Inventory</h1>
				</div>
				<div class="col-md-6 text-right">
					<!-- <button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Toy Type</button> -->
				</div>
			</div>
		</div>
		<div class="container mt-4">
			<h4>Instructions</h4>
			<p>Provide a name and basic purchase history details for the toy.</p>
		</div>
		<hr />
		<div class="container">
			<form id="step2form" class="needs-validation" novalidate>
				<div class="row">
					<div class="col-md-8">
						<div class="row">
							<div class="col-4">
								<div class="form-group">
									<label for="input-2">Toy Number</label>
									<input id="input-2" class="form-control" v-model="record.toy_number" placeholder="Required" required>
									<div class="invalid-feedback">Toy Numbers must be provided and unique.</div>
									<div class="text-danger" v-if="toy_number_unique_fail">Provided Toy Number is already in use.</div>
								</div>
							</div>
							<div class="col">
								<div class="form-group">
									<label for="input-3">Toy Name</label>
									<input id="input-3" class="form-control" v-model="record.toy_name" placeholder="Required" required>
									<div class="invalid-feedback">Toy Names are required.</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="col-md-4">
						<div class="form-group" v-if="toy_type_loaded">
							<label>Toy Type</label>
							<div>{{toy_type.toy_type_name}}</div>
						</div>
					</div> -->
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="vendor">Vendor</label>
							<input list="vendors" id="vendor" v-model="vendor" class="form-control" placeholder="Select Existing or Type New Vendor Name">
							<datalist id="vendors">
								<option v-for="v in vendors" :key="v.vendor_id" :value="v.vendor_name" />
							</datalist>
							<div class="badge badge-success" v-show="isNewVendor">New Vendor!</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label for="price">Price</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">$</div>
								</div>
								<input type="number" id="price" v-model="record.price" class="form-control">
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label for="purchase_date">Purchase Date</label>
							<input type="date" id="purchase_date" v-model="record.purchase_date" class="form-control">
						</div>
					</div>
				</div>
				<div class="row">

					<div class="col-md-3">
						<div class="form-group">
							<label for="category_id">Category</label>
							<select id="category_id" v-model="record.category_id" class="form-control">
								<option v-for="category in categories" :key="category.category_id" :value="category.category_id">{{category.category_name}}</option>
							</select>
						</div>
					</div>

					<div class="col-md-3" v-if="record.category_id==1">
						<div class="form-group">
							<label for="Author">Author</label>
								<input type="text" class="form-control" v-model="record.author" @input="filterAuthors()" autocomplete="off" @focus="author_modal = true">

						<div v-if="filteredAuthors && author_modal">
							<ul class="list-group">
								<li v-for="filteredAuthor in filteredAuthors" v-bind:key="filteredAuthor" @click="setAuthor(filteredAuthor)" class="list-group-item list-group-item-action">
									{{filteredAuthor}}
								</li>
							</ul>
						</div>							
						</div>
					</div>



					<div class="col-md-6">
						<div class="form-group">
							<label for="tags42">Tags</label>
							<vue-tags-input	id="tags43" v-model="tag" :tags="tags" :autocomplete-items="toptags" @tags-changed="newTags => tags = newTags"  />
							<div class="mt-1">
								<span v-for="t in toptags" :key="t.tag_name" class="text-secondary text-muted" style="word-wrap: break-word;">
									<small><a href="#" @click.prevent="add_tag_by_anchor" class="text-secondary mr-2 clickable">#{{t.tag_name}}</a></small> 
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<hr />
		<div class="container">
			<div class="row">
				<div class="col text-left">
					<button type="button" id="backBtn" class="btn btn-light" @click="back">Back</button>
				</div>
				<div class="col text-right">
					<button type="button" id="nextBtn" class="btn btn-primary" @click="save_toy">Save Changes and Continue</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// import Vue from 'vue'
// https://github.com/JohMun/vue-tags-input
import VueTagsInput from '@johmun/vue-tags-input'

export default {
	name: 'ToyNewStep1',
	components: {
		VueTagsInput,
	},
	data: function(){
		return {
			toy_type_loaded: false,
			toy_type: {},
			toy_number_unique_fail: false,
			categories: [],
			record: {
				toy_id: 0,
				toy_number: '',
				toy_name: '',
				toy_type_id: 0,
				vendor_id: null,
				price: null,
				purchase_date: null,
				author:'',
				category_id:null,
			},
			vendors: [],
			vendor: null, // Selected Vendor
			tag: '',
			tag_input: null,
			tags: [],
			photos: [],
			toptags: [],
			newTags: [],
			authors: [],
			filteredAuthors:[],
			author_modal: false,
		}
	},
	watch: {
		'vendor': function(){
			this.evt_vendor_change()
		},
	},
	computed: {
		isNewVendor(){
			let v = this;
			return (v.vendor != null && !(v.record.vendor_id > 0))
		},
	},
	methods: {

		filterAuthors(){

			this.filteredAuthors = this.authors.filter(author =>{

					if(this.record.author.length > 0)
					{
						return author.toString().toLowerCase().includes(this.record.author.toLowerCase());
					}
				
			});

		},

		setAuthor(author){

			this.record.author = author;
			this.author_modal = false;

		},		

		back(){
			this.$router.push('/admin/toys')
		},
		validate(){
			let v = this
			let fields_not_empty = ['toy_number','toy_name']
			for(let i in fields_not_empty)
			{
				let f = fields_not_empty[i]

				if( ['',null,undefined].indexOf(v.record[f]) > -1 )
				{
					let form = document.getElementById('step2form')
					form.classList.add('was-validated');
					return false;
				}
			}
			return true;
		},
		save_toy(){
			let v = this
			if( v.validate() )  //eslint-disable-line
				{
				v.api.post({
					url: '/admin/toy/0',
					data: JSON.stringify({toy:v.record,tags:v.tags}),
					contentType: 'json',
					success: function(response){
						if(response.result)
						{
							let msg = 'New Toy Added'
							v.toast(msg,'success')
							v.$router.push('/admin/toy/'+String(response.toy.toy_id))
						}
						else
						{
							let msg = 'Validation failed adding Toy'
							v.toast(msg,'info')
							console.error(response.errors)
							v.toy_number_unique_fail = true;
						}
					},
					fail: function(x,s,m){
						let msg = 'Addition of Toy Failed'
						v.toast(msg,'danger')
						console.error(msg,x,s,m)
					},
				})
			}
		},
		load_categories(){
			let v = this;
			v.api.get({
				url: '/categories',
				success: function(response){
					v.categories = []
					for(let i in response){
						let category = response[i]
						v.categories.push(category)
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories List'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},


		load_authors(){
			let v = this
			v.api.get({
				url: '/admin/authors',
				success: (response)=>{
					for(let i in response){
						let author = response[i]
						v.authors.push(author["author"])
					}
				},
				fail: (x,s,m)=>{
					let msg = 'Failed to retrieve Authors from server'
					console.error(msg,x,s,m)
					v.toast(msg,'danger')
				},
			})
		},		

		load_random_toy_number(){
			let v = this
			v.api.get({
				url: '/admin/toy/random_id',
				success: function(response){
					v.record.toy_number = response.toy_number


				}
			})
		},


		evt_vendor_change(){
			let v = this;
			v.record.vendor_id = null;
			v.vendors.forEach(function(element){
				if( String(element.vendor_name).toLowerCase() == String(v.vendor).toLowerCase() )
				{
					v.record.vendor_id = element.vendor_id;
				}
			});
		},
		load_vendors(){
			let v = this;
			v.api.get({
				url: '/admin/vendors',
				success: function(response){
					v.vendors = response;
				},
			})
		},
		load_toptags(){
			let v = this
			v.toptags = []
			v.api.get({
				url: '/admin/toptags',
				success: (response)=>{
					for(let i in response){
						let tag = response[i]
						tag.text = tag.tag_name
						v.toptags.push(tag)
					}
				},
				fail: (x,s,m)=>{
					let msg = 'Failed to retrieve Top Tags from server'
					console.error(msg,x,s,m)
					v.toast(msg,'danger')
				},
			})
		},
		add_tag_by_anchor: function(event){
			let tag = event.target.text
			tag = tag.replace('#','')
			if(this.tags.length == 0 || this.tags.indexOf(tag) == -1){
				this.tags.push({text:tag})
			}
		},
	},
	mounted: function(){

		this.load_vendors();
		this.load_categories();
		this.load_toptags();
		this.load_random_toy_number();
		this.load_authors();
	},
}
</script>
