<template>
	<div class="vendors">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Vendors</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Vendor</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<table class="table table-hover table-hover-pointer">
				<thead>
					<tr>
						<th>Vendor Name</th>
						<th>Shipping Address</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="u in vendors" v-bind:key="u.vendor_id" @click="edit(u.vendor_id)">
						<td>{{u.vendor_name}}</td>
						<td>{{u.shipping_address_rma}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	name: 'VendorList',
	components: {},
	data: function(){
		return {
			vendors: [],
			vendors_loaded: false,
		}
	},
	methods: {
		add: function(){
			this.$router.push('/admin/vendor/0');
		},
		edit: function(id){
			this.$router.push('/admin/vendor/'+String(id));
		},
		load: function(){
			var v = this;
			this.api.get({
				url: '/admin/vendors',
				success: function(response){
					v.vendors = response;
					v.vendors_loaded = true;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
	},
	mounted: function(){
		this.load()
	},
}
</script>
