<template>
	<div class="users">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Clients</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Client</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<table class="table table-hover table-hover-pointer">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Consultant</th>
						<th>Active</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in clients" v-bind:key="c.client_id" @click="edit(c.client_id)">
						<td>{{c.first_name}}</td>
						<td>{{c.last_name}}</td>
						<td>{{c.interventionist_first}} {{c.interventionist_last}}</td>
						<td>
							<b-icon-check-circle-fill class="text-success" v-if="c.active" />
							<b-icon-x-circle-fill class="text-secondary" v-if="!c.active" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Clients',
	components: {},
	data: function(){
		return {
			show: true,
			clients: [],
		}
	},
	methods: {
		add: function(){
			this.$router.push('/admin/client/0');
		},
		edit: function(id){
			this.$router.push('/admin/client/'+String(id));
		},
		load: function(){
			var v = this;
			this.api.get({
				url: '/admin/clients',
				success: function(response){
					v.clients = response;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
	},
	mounted: function(){
		this.load()
	},
}
</script>
