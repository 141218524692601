<template>
	<div class="vendor">
		<div v-if="!record_loaded">
			<h1>Loading..</h1>
		</div>
		<div class="bg-dark text-light p-3" v-if="record_loaded">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-person-circle /> Vendor {{record.vendor_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<form>
				<div class="form-row">
					<div class="col">
						<div class="form-group">
							<label for="vendor_name">Vendor Name</label>
							<input id="vendor_name" type="text" class="form-control" v-model="record.vendor_name" />
							<small class="form-text text-muted">The name of your vendor.</small>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label for="shipping_rma">Shipping Address for Returns</label>
							<textarea id="shipping_rma" type="text" class="form-control" v-model="record.shipping_address_rma"></textarea>
							<small class="form-text text-muted">The address used for shipping returns back.</small>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="col">
						<div class="form-group">
							<label for="note">Note(s)</label>
							<textarea id="note" v-model="record.note" rows="5" class="form-control"></textarea>
							<small class="form-text text-muted">General notes on this vendor.</small>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Vendor',
	components: {},
	data: function(){
		return {
			options: {
				type: [
					{value:'system',text:'System User'},
					{value:'interventionist',text:'Consultant'},
					],
				accounts: [],
			},
			loading: false,
			record_loaded: false,
			record: {}
		}
	},
	methods: {
		cancel: function(){
			this.loading = true;
			this.$router.push('/admin/vendors');
		},
		remove: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Vendor',
				'Are you sure?',
				'danger',
				function(answer){
					v.loading = false;
					if( answer) {
						v.api.delete({
							url: '/admin/vendor/'+String(v.record.vendor_id),
							success: function(){
								v.toast('Vendor Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								let msg = 'Vendor Deletion Failed';
								v.toast(msg,'danger');
								console.error(msg,x,s,m)
							},
						});
					}
				});
		},
		save: function(){
			let v = this;
			let id = (this.record.vendor_id ? this.record.vendor_id : 0);
			v.loading = true;
			v.api.post({
				url: '/admin/vendor/'+String(id),
				data: v.record,
				success: function(){
					v.toast('Vendor Changes Saved','success');
					setTimeout(v.cancel,500);
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Vendor Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load: function(id){
			var v = this;
			v.record_loaded = false;
			this.api.get({
				url: '/admin/vendor/'+String(id),
				success: function(response){
					for(var i in response){
						v.record[i] = response[i];
					}
					v.toast('Vendor Loaded')
					v.record_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Vendor Failed to Load';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
		fetch_accounts: function(){
			var v = this;
			v.api.get({
				url: '/admin/accounts',
				success: function(response){
					for(var i in response){
						v.options.accounts.push( {
							value: response[i].account_id,
							text: response[i].account_name,
						});
					}
				},
				fail: function(x,s,m){
					let msg = 'Unable to load Accounts';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
	},
	mounted: function(){
		var id = this.$route.params.id;
		this.fetch_accounts();
		this.load(id)
	},
}
</script>
