<template>
	<div id="scan-panel">
		<div class="mb-4 mt-5">
			<ul class="nav nav-tabs">
				<li class="nav-item" v-if="!isMobile()">
					<div role="button" class="nav-link" :class="{active:method=='scanner'}" @click="select_method('scanner')">
						<img src="@/assets/scanner-icon-16.png"> Hand Scanner
					</div>
				</li>
				<li class="nav-item" v-if="isMobile()">
					<div role="button" class="nav-link" :class="{active:method=='phone'}" @click="select_method('phone')">
						<i class="bi bi-phone"></i> Mobile Camera
					</div>
				</li>
				<li class="nav-item">
					<div role="button" class="nav-link" :class="{active:method=='keyboard'}" @click="select_method('keyboard')">
						<i class="bi bi-keyboard"></i> Keyboard Entry
					</div>
				</li>
			</ul>
		</div>
		<div v-show="method=='phone'">
			<div class="float-left mr-2">
				<div class="text-center"><img src="/img/samsung.png" style="height: 100px;"></div>
			</div>
			<div class="float-left mr-2">
				<a class="btn btn-info btn-block" :href="scanner_url"><i class="bi bi-upc-scan"></i> Scan Now</a>
			</div>
			<div>
				<p class="text-muted">You must have the ZXing Barcode Scanner app installed on your phone for this to work.</p>
				<!-- <p>zxing://scan/?ret=http://foo.com/products/{CODE}/description&SCAN_FORMATS=UPC_A,EAN_13</p> -->
			</div>
		</div>
		<div v-show="method=='scanner'">
			<div class="float-left">
				<div class="text-center"><img src="/img/scanner.png" style="height: 100px;"></div>
			</div>
			<div>
				<div class="form-group">
					<label for="scan_text">Scan a Toy Label</label>
					<input type="text" class="form-control" id="scan_text" v-model="scan_text" @keyup="scan_code_desktop" ref="scan_text">
					<p class="text-muted"><small>You must click inside the input above before scanning with your scanning device.</small></p>
				</div>
			</div>
		</div>
		<div v-show="method=='keyboard'">
			<div>
				<div class="text-center float-left mr-2"><img src="/img/keyboard.png" style="height: 100px;"></div>
				<div class="form-group">
					<label for="kb_text">Type in a Toy Number:</label>
					<div class="form-inline">
						<input type="text" class="form-control" id="kb_text" v-model="kb_text" ref="kb_text" v-on:keyup.enter="kb_submit_code">
						<button type="button" class="btn btn-primary" @click="kb_submit_code"><i class="bi bi-arrow-right"></i></button>
					</div>
					<p class="text-muted"><small>Just use your keyboard to type in the number(s) on the toys.</small></p>
				</div>
			</div>
		</div>
		<div v-if="history.length>0" class="border rounded p-2">
			<strong>History:</strong><br />
			<ul>
				<li v-for="(h,index) in history" :key="index">
					{{h}}
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'ScanPanel',
		em: '#scan-panel',
		data(){
			return {
				method: 'scanner',
				kb_text: null,
				scan_text: null,
				history: [],
				scan_text_timeout: null,
			}
		},
		computed: {
			scanner_url(){
				//TODO come up with a better method, this SUCKS!
				return 'zxing://scan/?ret='+window.location.protocol+'//'+window.location.hostname+'/scan?add={CODE}';
			},
		},
		methods: {
			select_method(method){
				this.method = method
				this.$nextTick(function(){
					switch(this.method)
					{
						case 'scanner':
							this.$refs.scan_text.focus()
							break
						case 'keyboard':
							this.$refs.kb_text.focus()
							break
					}
				})
			},
			kb_submit_code(){ // Method used by Keyboard Input
				this.$emit('scan',this.kb_text)
				this.history.push(this.kb_text)
				this.kb_text = null
			},
			scan_code_desktop(){
				if( this.scan_text_timeout )
					clearTimeout(this.scan_text_timeout)

				let v = this
				this.scan_text_timeout = setTimeout(function(){
					v.$emit('scan',v.scan_text)
					v.history.push(v.scan_text)
					v.scan_text = null
				},500)
			},
		},
		mounted: function(){
			this.select_method('scanner')
		},
	}
</script>