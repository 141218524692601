<template>
	<div class="toys container">
		<ToyGrid />
	</div>
</template>

<script>
import ToyGrid from '@/components/ToyGrid.vue'

export default {
	name: 'Toys',
	data: function(){
		return {
			toys: [],
		}
	},
	components: {
		ToyGrid,
	},
	methods: {
	},
	mounted: function(){
	},
}
</script>

