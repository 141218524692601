import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import {BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.js'
import '@popperjs/core'
import jQuery from 'jquery'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.prototype.jquery = jQuery


// Custom SCSS is configured in app.scss
import './bootstrap-custom.scss'
import './vue-transitions.css'

// Setup Custom ACLCheck on Vue
import aclCheck from './security.js'
Vue.prototype.aclCheck = function(constant_name,require_all=false){
	return aclCheck(constant_name,require_all)
}

// Setup XHR Functions
import {ajax, parse_params} from './ajax'

Vue.prototype.api = {
	get: (url,data=[],success=null,fail=null)=>{
		var params = {};
		if( typeof url == 'object')
			params = parse_params(url);
		else
			params = parse_params({url:url,data:data,success:success,fail:fail});
		params.method = 'GET';
		ajax(params);
	},
	put: (url,data=[],success=null,fail=null)=>{
		var params = {};
		if(typeof url == 'object')
			params = parse_params(url);
		else
			params = parse_params({url:url,data:data,success:success,fail:fail});
		params.method = 'PUT';
		ajax(params);
	},
	post: (url,data=[],success=null,fail=null)=>{
		var params = {};
		if( typeof url == 'object')
			params = parse_params(url);
		else
			params = parse_params({url:url,data:data,success:success,fail:fail});
		params.method = 'POST';
		ajax(params);
	},
	delete: (url,data=[],success=null,fail=null)=>{
		var params = {};
		if( typeof url == 'object')
			params = parse_params(url);
		else
			params = parse_params({url:url,data:data,success:success,fail:fail});
		params.method = 'DELETE';
		ajax(params);
	},
	upload: (url,data=[],success=null,fail=null)=>{
		var params = {};
		if( typeof url == 'object')
			params = parse_params(url);
		else
			params = parse_params({url:url,data:data,success:success,fail:fail});
		params.method = 'POST';
		params.contentType = false;
		params.processData = false;
		params.cache = false;
		ajax(params);
	},
}

Vue.mixin({
	methods: {
		toast(msg='Empty Toast',variant='info',autohide=2000,append = false,title="ECIP Toy Library") {
				this.$bvToast.toast(msg, {
				title: title,
				autoHideDelay: autohide,
				appendToast: append,
				variant: variant,
				toaster: 'b-toaster-bottom-right',
			})
		},
		cancel: function(){
			this.$router.go(-1);
		},
		session_id(session_id=null){
			// Loads OR Sets the current session id as a cookie
			if( session_id != null )
				this.$cookie.set('toyapp_session_id',session_id)
		},
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		},
		get_url_arg(name){
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line
			var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
			var results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		},
		closeModals: function()
		{
			jQuery(".modal").each(function()
			{
				var me = jQuery(this);

				if(me.hasClass("fade"))
				{
					me.removeClass("fade");
					me.modal("hide");
					me.addClass("fade");
				}
				else
				{
					me.modal("hide");
				}
			});
		},
		nerivon_confirm: function(title, message, type, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="bi bi-exclamation-square text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="bi bi-x-square text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="bi bi-check-square text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="bi bi-info-square text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">No</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Yes</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			var vm = this;

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				// Make sure all modals are closed. Sometimes the backdrop gets stuck.
				vm.closeModals();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_alert: function(title, message, type, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<em class="bi bi-exclamation-square text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="bi bi-x-square text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="bi bi-check-square text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="bi bi-info-square text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_input: function(title, message, callback)
		{
			// var vm = this;
			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title"><em class="fas fa-keyboard"></em> ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '<br><br><input id="nvModalInput" class="form-control" autofocus /></div> \
						<div class="modal-footer text-center"> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">Cancel</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = document.getElementById(\'nvModalInput\').value">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 0 ? false : nvModalAnswer));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_choice: function(title, message, type, choices, callback)
		{
			// var vm = this;
			var icon = "";
			var choices_html = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="bi bi-exclamation-square text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="bi bi-x-square text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="bi bi-check-square text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="bi bi-info-square text-info"></em>';
			}

			for(var i=0; i<choices.length; i++)
			{
				choices_html += '<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'' + choices[i][0] + '\'">' + choices[i][1] + '</button>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" />' + choices_html + ' \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'\'">Cancel</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback(nvModalAnswer);
			});

			jQuery("#nvModal").modal("show");
		},
		showWarning: function(message, text, callback)
		{
			this.nerivon_confirm(message, text, "warning", callback);
		},
		showError: function(message, text, callback)
		{
			this.nerivon_alert(message, text, "error", callback);
		},
		showSuccess: function(message, text, callback)
		{
			this.nerivon_alert(message, text, "success", callback);
		},
		showInfo: function(message, text, callback)
		{
			this.nerivon_alert(message, text, "info", callback);
		},
		getViewPortDimensions: function()
		{
			let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			return {width: vw, height: vh}
		}
	},
})

// https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/
export const EventBus = new Vue();

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
