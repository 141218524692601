<template>
	<div class="toy_types">
		<div class="container mt-3">
			<div class="row">
				<div class="col-3">
					<strong>Filters</strong>
					<hr />
					<div class="form-group">
						<div class="input-group">
							<input type="text" id="keyword" placeholder="by keyword" class="form-control" v-model="search_keyword" @keyup="evt_filter_click">
							<div class="input-group-append">
								<button type="button" class="btn btn-sm btn-info" @click="search_keyword='';evt_filter_click();"><i class="bi bi-x-circle"></i></button>
							</div>
						</div>
					</div>
					<hr />
					<strong>Categories</strong>
					<div class="form-check" v-for="c in categories" :key="c.category_name">
						<input class="form-check-input" type="checkbox" v-model="search_categories" :value="c.category_id" :id="'categorychk_'+c.category_id" @click="evt_filter_click">
						<label class="form-check-label" :for="'categorychk_'+c.category_id">{{c.category_name}}</label>
					</div>
					<hr />
					<strong>Tags</strong>
					<div class="form-check" v-for="tag in tags" :key="tag.tag_id">
						<input class="form-check-input" type="checkbox" v-model="search_tags" :value="tag.tag_id" :id="'tagchk_'+tag.tag_id" @click="evt_filter_click">
						<label class="form-check-label" :for="'tagchk_'+tag.tag_id">{{tag.tag_name}}</label>
					</div>
				</div>
				<div class="col">
					<table class="table table-hover table-hover-pointer">
						<thead>
							<tr>
								<th>Name</th>
								<th>Category</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="tt in toy_types" v-bind:key="tt.toy_type_id" @click="row_click(tt.toy_type_id)">
								<td>{{tt.toy_type_name}}</td>
								<td>{{tt.category_name}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ToyTypes',
	props: {
		mode: String, //select, admin
	},
	components: {},
	data: function(){
		return {
			toy_types: [],
			categories: [],
			tags: [],
			search_tags: [],
			search_keyword: '',
			search_categories: [],
			timeouts: {
				filter: null,
			},
		}
	},
	methods: {
		row_click(toy_type_id){
			let v = this;
			switch(v.mode){
				case 'admin':
					this.$router.push('/admin/toy_type/'+String(toy_type_id));
					break;
				case 'select':
					v.$router.push('/admin/newtoy/step2/'+String(toy_type_id))
					break;
			}
		},
		load: function(){
			var v = this;
			this.api.post({
				url: '/admin/toy_types',
				data: JSON.stringify({keyword:v.search_keyword,tags:v.search_tags,categories:v.search_categories}),
				contentType: 'json',
				success: function(response){
					v.toy_types = response;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
		load_categories(){
			let v = this;
			this.api.get({
				url: '/admin/categories',
				success: function(response){
					v.categories = []
					if(response.length)
					{
						for(let i in response)
						{
							let category = response[i]
							v.categories.push(category)
						}
					}
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Categories'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_tags(){
			let v = this;
			this.api.get({
				url: '/admin/toptags',
				success: function(response){
					v.tags = []
					if(response.length)
					{
						for(let i in response){
							let row = response[i]
							v.tags.push(row)
						}
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Tags'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				}
			})
		},
		evt_filter_click(){
			let v = this;
			if(v.timeouts.filter)
				clearTimeout(v.timeouts.filter)
			v.timeouts.filter = setTimeout(v.load,300);
		},
	},
	mounted: function(){
		this.load_tags()
		this.load_categories()
		this.load()
	},
}
</script>
