<template>
	<div class="toy-cart-item" v-if="toy_loaded">
		<div class="row mt-5">
			<div class="col-2">
				<img class="rounded toythumb" :src="toy.primary_photo_url+'?s=200'" :alt="toy.primary_photo_caption" />
			</div>
			<div class="col-10">
				<h4>{{toy.toy_name}} - [{{toy.toy_number}}]</h4>
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md">
						<div class="form-group">
							<label for="status">Status</label><br />
							<div class="badge" :class="status_class(toy.status)">{{status_text(toy.status)}}</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md">
						<div class="form-group">
							<label for="condition">Condition</label><br />
							<div class="badge" :class="condition_class(toy.condition)">{{condition_text(toy.condition)}}</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md" v-if="toy.status!='to_library'">
						<div class="form-group">
							<label>Possession</label>
							<small>
								<div v-if="toy.status=='to_interventionist'">{{toy.interventionist_name}}</div>
								<!-- <div v-if="toy.status=='to_library'">In Library</div> -->
								<div v-if="toy.status=='to_client'">{{toy.client_name}}</div>
								<div v-if="toy.status=='to_vendor_repair'">{{toy.vendor_name}}</div>
								<div v-if="toy.status=='to_vendor_return'">{{toy.vendor_name}}</div>
								<div v-if="toy.status=='to_vendor_cleaning'">{{toy.vendor_name}}</div>
							</small>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md" v-if="toy.status=='to_library'">
						<div class="form-group">
							<label>Storage Location</label>
							<small>
								<div>{{toy.storage_location}}</div>
							</small>
						</div>
					</div>
					<div class="col-xs-12 col-sm-12 col-md text-right" v-if="allow_remove || allow_give || allow_retrieve || allow_condition_changes">
						<button type="button" class="btn btn-sm btn-danger mt-1 btn-block" @click="remove_click()" v-if="allow_remove===true">
							<i class="bi bi-times"></i> {{!remove_label?'Remove':remove_label}}
						</button>
						<button type="button" class="ml-1 btn btn-sm btn-warning mt-1 btn-block" @click="give_click(toy.toy_id)" v-if="allow_give===true && toy.status=='to_interventionist'">
							<i class="bi bi-box2-heart"></i> Give to Client
						</button>
						<button type="button" class="ml-1 btn btn-sm btn-info mt-1 btn-block" @click="retrieve_click(toy.toy_id)" v-if="allow_retrieve===true && toy.status=='to_client'">
							<i class="bi bi-arrow-left"></i> Retrieve from Client
						</button>
						<button type="button" class="ml-1 btn btn-sm btn-light mt-1 btn-block" @click="condition_click(toy.toy_id,toy.condition)" v-if="allow_condition_changes===true">
							<i class="bi bi-exclamation-diamond"></i> Report Condition
						</button>
					</div>
				</div>
				<div class="row" v-if="toy.notes">
					<div class="col">
						<div class="form-group">
							<label for="notes">Notes on Condition:</label><br />
							<p class="text-muted"><small>{{toy.notes}}</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
	@media screen and ( max-width: 599px ) {
		img.toythumb { width: 55px; }
	}
	@media screen and ( min-width: 600px) {
		img.toythumb { width: 150px;}
	}
</style>

<script>
// import Cookies from 'js-cookie'
// import {EventBus} from '../main'
export default {
	name: 'ToyCartItemAdvanced',
	props: {
		toy_id: Number,
		allow_retrieve: Boolean,
		allow_remove: Boolean,
		allow_give: Boolean,
		allow_condition_changes: Boolean,
		remove_label: String,
	},
	data: function(){
		return {
			toy_loaded: false,
			toy: null,
			statuses: {
				'to_library':'In Library',
				'to_client':'With Client',
				'to_interventionist':'With Consultant',
				'to_retirement':'Removed from Library',
				'to_vendor_repair':'Sent for Repair',
				'to_vendor_return':'Returned to Vendor',
				'to_vendor_cleaning':'Sent for Cleaning'
			},
			conditions: {
				'good': 'Good Condition',
				'needs_repair': 'Needs Repair',
				'needs_replacement':'Needs Replacement',
				'needs_cleaning':'Needs Cleaning',
				'missing_pieces':'Missing Pieces',
			},
		}
	},
	methods: {
		load_toy(toy_id){
			let v = this
			v.toy_loaded = false
			v.api.get({
				url: '/toy/'+String(toy_id),
				success: function(response){
					v.toy = response
					v.toy_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Toy '+String(x.toy_id)
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		status_text(status){
			if(status)
				return this.statuses[status]
			else
				return ''
		},
		status_class(status){
			if(!status)
				return 'badge-light';
			if( status == 'to_library' ){
				return 'badge-success';
			}
			if( status == 'to_interventionist' ){
				return 'badge-warning';
			}
			if( status == 'to_client' ){
				return 'badge-info';
			}
			return 'badge-danger';
		},
		condition_text(condition){
			if(condition)
				return this.conditions[condition]
			else
				return ''
		},
		condition_class(condition){
			if( condition == 'good' ){
				return 'badge-success'
			}
			return 'badge-warning'
		},
		remove_click(toy_id){
			this.$emit('remove',toy_id)
		},
		give_click(toy_id){
			this.$emit('give',toy_id)
		},
		retrieve_click(toy_id){
			this.$emit('retrieve',toy_id)
		},
		condition_click(toy_id,condition){
			this.$emit('condition',toy_id,condition)
		},
	},
	mounted: function(){
		this.load_toy(this.toy_id)
	},
}
</script>
