 <template>
	<div class="modal" tabindex="-1" id="condition-modal">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Report Condition of Toy</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-6">
							<label for="condition">Indicate the new Condition</label>
							<select class="form-control" :size="rowcount" v-model="condition" id="condition">
								<option v-for="(c,index) in conditions" :key="index" :value="c.id">
									<i class="bi bi-flag"></i> {{c.condition}}
								</option>
							</select>
						</div>
						<div class="col-md-6">
							<label for="notes">Notes on Condition</label>
							<textarea class="form-control" v-model="notes" id="notes" placeholder="eg. Missing two pieces" :rows="rowcount-2"></textarea>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					<button type="button" class="btn btn-primary" @click="confirm_click">Confirm</button>
				</div>
			</div>
		</div>
	</div>
 </template>
 <script>
	import jQuery from 'jquery'

	export default{
		name: 'ConditionModal',
		data(){return{
			conditions: [
				{id:'good',condition:'Good Condition'},
				{id:'needs_repair',condition:'Needs Repair'},
				{id:'needs_replacement',condition:'Needs Replacement'},
				{id:'needs_cleaning',condition:'Needs a Cleaning'},
				{id:'missing_pieces',condition:'Missing Pieces'},
			],
			condition: null,
			rowcount: 10,
			notes: null,
			toy_id: null,
		}},
		methods: {
			open(toy_id,condition){
				this.toy_id = toy_id
				this.condition = condition
				jQuery('#condition-modal').modal('show')
			},
			close(){
				jQuery('#condition-modal').modal('hide')
			},
			confirm_click(){
				// Takes the selected condition and returns it through an emit event
				if( this.condition != null )
					this.$emit('select',this.toy_id,this.condition,this.notes)
				this.close()
			},
		},
		mounted(){
			if( this.isMobile() )
				this.rowcount = 5
		},
	}
</script>