<template>
	<div class="cart container">
		<h3 class="mt-5">My Pick List</h3>
		<hr />
		<div v-for="toy in picklist" :key="toy.pick_list_id" >
			<!-- <ToyCartItem :toy_id="toy.toy_id" :pick_list_id="toy.pick_list_id" @deleted="get_cart_contents" /> -->
			<ToyCartItemAdvanced 
				:toy_id="toy.toy_id" 
				:allow_remove="true" 
				@remove="remove_click(toy.pick_list_id)" 
				remove_label="Remove from My List" />
		</div>
		<hr />
		<div class="row">
			<div class="col">
				<button type="button" class="btn btn-light" @click="empty_pick_list">Empty Pick List</button>
			</div>
			<div class="col text-right">
				<!-- <a class="none" href="/scan"> -->
				<button type="button" class="btn btn-lg btn-success" @click="checkout">Checkout</button>
				<!-- </a> -->
			</div>
		</div>
	</div>
</template>
<script>

// import Cookies from 'js-cookie'
import ToyCartItemAdvanced from '@/components/ToyCartItemAdvanced.vue'
import {EventBus} from '../main'

export default {
	name: 'Picks',
	components: {
		ToyCartItemAdvanced,
	},
	data: function(){
		return {
			cart: [],
			picklist:[],
		}
	},
	computed: {
		get_cart(){
			return this.cart
		}
	},
	methods: {
		checkout(){
			this.$router.push('/checkout');
		},
		remove_click(pick_list_id){
			let v = this
			v.nerivon_confirm("Return Pick","Do you want to remove this item from your pick list?","warning",function(answer){
				if( answer ) {
					v.api.delete({
						url: '/admin/picklist/'+String(pick_list_id),
						success: function(){
							v.load_picks()
						},
					})
				}
			})
		},
		load_picks(){
			let v = this
			v.api.get({
				url: '/admin/picklist',
				success: function(response){
					v.picklist = response
					EventBus.$emit('cart',v.picklist)
				},
				fail: function(x,s,m){
					let msg = 'Unable to load user picks'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		empty_pick_list(){
			let v = this
			v.api.get({
				url: '/admin/emptypicklist',
				success: function(response){
					v.picklist = response
					EventBus.$emit('cart',v.picklist)
				},
				fail: function(x,s,m){
					let msg = 'Unable to load user picks'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		}
	},
	mounted: function(){
		this.load_picks();
	},
}
</script>
