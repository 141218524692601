<template>
	<div class="scan">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-upc-scan /> Scanning</h1>
				</div>
				<div class="col-md-6">
					<ul class="text-muted">
						<li>Locate the QR code or number on the toy.</li>
						<li>Either Scan the Toy or type the Code into the provided box.</li>
						<li>Indicate the destination for the Toy</li>
						<li>Click the Confirm Button</li>
					</ul>
				</div>
			</div>
		</div>
		<div v-show="current_tab==1">
			<div class="container mt-4">
				<div class="row">
					<div class="col-sm-12 col-md-4" v-if="isMobile()">
						<div class="text-center"><img src="/img/samsung.png" style="height: 100px;"></div>
						<a class="btn btn-info btn-block" :href="scanner_url">Scan with Phone/Camera</a>
						<p class="text-muted">You must have the ZXing Barcode Scanner app installed on your phone for this to work.</p>
						<!-- <p>zxing://scan/?ret=http://foo.com/products/{CODE}/description&SCAN_FORMATS=UPC_A,EAN_13</p> -->
					</div>
					<div class="col-sm-12 col-md-0" v-if="isMobile()">
						<strong>..Or..</strong>
					</div>
					<div class="col-sm-12 col-md-4">
						<div class="row">
							<div class="col">
								<div class="text-center"><img src="/img/keyboard.png" style="height: 100px;"></div>
								<div class="form-group">
									<label for="kb_text">Type in a Toy Number:</label>
									<div class="form-inline">
										<input type="text" class="form-control" id="kb_text" v-model="kb_text">
										<button type="button" class="btn btn-primary" @click="submit_code">Submit</button>
									</div>
									<p class="text-muted"><small>Just use your keyboard to type in the number found on the toy in question.</small></p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-4" v-if="!isMobile()">
						<div class="text-center"><img src="/img/scanner.png" style="height: 100px;"></div>
						<div class="form-group">
							<label for="scan_text">Scan a Toy Label</label>
							<input type="text" class="form-control" id="scan_text" v-model="scan_text" @keyup="scan_code_desktop">
							<p class="text-muted"><small>You must click inside the input above before scanning with your scanning device.</small></p>
						</div>
					</div>
				</div>

				<div id="teststuff">
					<h3>Sandbox</h3>
					<div class="row">
						<div class="col-md-6">
							<div v-for="toy in toys" :key="toy.toy_id">
								<ToyCartItemAdvanced :toy_id="toy" :allow_move="true" :allow_delete="true" />
							</div>
						</div>
						<div class="col-md-6">
						</div>
					</div>
				</div>


				<div v-if="picklist.length>0">
					<hr/>
					<h3 class="mt-5">My Picklist Toys</h3>
					<div v-for="toy in picklist" :key="toy.pick_list_id">
						<ToyCartBasic :pick="toy" @deleted="load_picks" />
					</div>
				</div>

				<hr />
				<h3 class="mt-5">Cart</h3>
					<div v-for="toy_id in toys" :key="toy_id">
						<ToyRowHorizontal :toy_id="toy_id" v-on:change="update_change"></ToyRowHorizontal>
					</div>
				<hr />
				<div class="row">
					<div class="col text-right">
						<button type="button" class="btn btn-lg btn-light" @click="clear_list">Clear List {{toys.length}}</button>
					</div>
				</div>
				<hr />
				<p>Please select one of the following options for this selection of toys:</p>
				<div class="row">
					<div class="col-sm-12 col-md-4 mt-2">
						<button type="button" class="btn btn-light btn-lg btn-block">Cancel</button>
					</div>
					<div class="col-sm-0 col-md-4 mt-2">

					</div>
					<div class="col-sm-12 col-md-4 mt-2 text-right">Staff Member:<br />
						<strong>{{current_user_fullname}}</strong>
						<hr />
						<button type="button" class="btn btn-info btn-lg btn-block" @click="check_in">Return Toy(s) to Library</button>
						<button type="button" class="btn btn-success btn-lg btn-block" @click="check_out">Take Toy(s) from Library</button>
					</div>
				</div>
			</div>
		</div><!-- tab 1 -->
		<div v-show="current_tab==2">
			<div class="container mt-3 text-center">
				<h2>Checkout Process Completed</h2>
				<p>Have more toys to process?</p>
				<button type="button" class="btn btn-primary btn-lg" @click="current_tab=1">Scan More Toys</button>
			</div>
		</div>
	</div>
</template>

<script>

import ToyRowHorizontal from '@/components/ToyRowHorizontal.vue'
import ToyCartBasic from '@/components/ToyCartBasic.vue'
import Cookies from 'js-cookie'
import ToyCartItemAdvanced from '@/components/ToyCartItemAdvanced.vue'

export default {
	name: 'Scan',
	data: function(){
		return {
			kb_text: null,
			picklist:[],
			scan_text: null,
			scan_text_timeout: null,
			toys: [],
			changes: {}, // Changes to the condition with notes for each toy
			current_tab: 1,
			current_user_fullname: 'Not Logged In',
		}
	},
	components: { 
		ToyRowHorizontal,
		ToyCartBasic,
		ToyCartItemAdvanced,
	},
	computed: {
		scanner_url(){
			return 'zxing://scan/?ret='+window.location.protocol+'//'+window.location.hostname+'/scan?add={CODE}';
		},
	},
	methods: {
		parse_changes(){
			let v = this;
			let changes = [];

			for(let i in v.toys)
			{
				let toy = {
					toy_id: v.toys[i],
					status: null,
					condition: null,
					note: null,
				}

				for(let x in v.changes)
				{
					let change = v.changes[x];
					if( toy.toy_id == change.toy_id )
					{
						toy.status = change.status;
						toy.condition = change.condition;
						toy.note = change.note;
					}
				}
				changes.push(toy);
			}

			return changes;
		},
		check_in(){
			let v = this;
			v.nerivon_confirm(
				'Return Toys',
				'Click YES to confirm that you are returning the scanned toys to the toy library',
				'success',
				function(answer){
					if( answer )
					{
						v.api.post({
							url: '/checkout',
							data: JSON.stringify({status: 'to_library', changes: v.parse_changes()}),
							contentType: 'json',
							success: function(response){
								if(response.result)
								{
									v.current_tab = 2;
									v.toys = [];
									v.changes = {};
									v.toast('All Toys Checked In','success');
									Cookies.remove('scanned_toys');
								}
							},
							fail: function(x,s,m){
								let msg = 'Failed to Complete Checkin';
								v.toast(msg,'danger');
								console.error(msg,x,s,m);
							},
						});
					}
				});
		},
		check_out(){
			let v = this;
			v.nerivon_confirm(
				'Take Toys',
				'Click YES to confirm that you are taking all of the selected toys from the toy library. These will be checked into the posession of <strong>'+String(v.current_user_fullname)+'</strong>. Are you sure?',
				'warning',
				function(answer){
					if( answer )
					{
						v.api.post({
							url: '/checkout',
							data: JSON.stringify({status: 'to_interventionist', changes: v.parse_changes()}),
							contentType: 'json',
							success: function(response){
								if(response.result)
								{
									v.current_tab = 2;
									v.toys = [];
									v.changes = {};
									v.toast('All Toys Checked Out','success');
									Cookies.remove('scanned_toys');
								}
							},
							fail: function(x,s,m){
								let msg = 'Failed to Complete Checkout';
								v.toast(msg,'danger');
								console.error(msg,x,s,m);
							},
						});
					}
				});
		},
		update_change(data){
			let v = this;
			if( data.toy_id != undefined )
			{
				v.changes[String(data.toy_id)] = {
					toy_id: data.toy_id,
					condition: data.condition,
					note: data.note
				};
			}
		},
		scan_code_desktop(){
			let v = this;

			// // Not my favourite solution as it involves a page refresh but it works
			if( v.scan_text_timeout )
				clearTimeout(v.scan_text_timeout);

			// v.scan_text_timeout = setTimeout(function(){
			// 	window.location.href = '/scan?add='+String(v.scan_text);
			// },500);

			v.scan_text_timeout = setTimeout(function(){
				let toy_number = v.scan_text;
				v.api.get({
					url: '/toy_by_number/'+String(toy_number),
					success: function(response){
						if( v.toys.indexOf(response.toy_id) == -1 )
						{
							v.toys.push(response.toy_id);
							Cookies.set('scanned_toys',v.toys);
							v.toast('Toy Added to List','success');
						}
						else
							v.toast('Toy Already In List','warning');
						v.scan_text = null;
					},
					fail: function(x,s,m){
						let msg = 'Unable to load Toy';
						if( x.status == 404 )
							v.toast('Scanned Toy Number is Invalid, Please try your scan again.','warning');
						else					
							v.toast(x.statusText,'danger');
						console.error(msg,x,s,m);
					},
				});
			},500);
		},
		add_picked_toy(toy_id){
			let v = this;
			v.api.get({
				url: '/toy/'+String(toy_id),
				success: function(response){
					if( v.toys.indexOf(response.toy_id) == -1 )
					{
						v.toys.push(response.toy_id);
						Cookies.set('scanned_toys',v.toys);
						v.toast('Toy Added to List','success');
					}
					else
						v.toast('Toy Already In List','warning');
					v.kb_text = null;
				},
				fail: function(x,s,m){
					let msg = 'Unable to load Toy';
					if( x.status == 404 )
						v.toast('Specified Toy Number Invalid, Please try another Number.','warning');
					else					
						v.toast(x.statusText,'danger');
					console.error(msg,x,s,m);
				},
			});
			// Add Toy ID to List
		},
		submit_code(){ // Method used by Keyboard Input
			let v = this;
			// Read Code from text field
			let toy_number = v.kb_text;
			// Get Toy from Server
			v.api.get({
				url: '/toy_by_number/'+String(toy_number),
				success: function(response){
					if( v.toys.indexOf(response.toy_id) == -1 )
					{
						v.toys.push(response.toy_id);
						Cookies.set('scanned_toys',v.toys);
						v.toast('Toy Added to List','success');
					}
					else
						v.toast('Toy Already In List','warning');
					v.kb_text = null;
				},
				fail: function(x,s,m){
					let msg = 'Unable to load Toy';
					if( x.status == 404 )
						v.toast('Specified Toy Number Invalid, Please try another Number.','warning');
					else					
						v.toast(x.statusText,'danger');
					console.error(msg,x,s,m);
				},
			});
			// Add Toy ID to List
		},
		clear_list(){
			Cookies.remove('scanned_toys');
			this.toys = [];
		},
		load_picks(){
			let v = this
			v.api.get({
				url: '/admin/picklist',
				success: function(response){
					v.picklist = response
				},
				fail: function(x,s,m){
					let msg = 'Unable to load user picks'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
	},
	mounted: function(){
		this.load_picks()
		// Update the current toy list - ONLY for Mobile Scans using the 3rd party scanning app ZXing
		let scanned_toys = [];
		try{
			if(typeof Cookies.get('scanned_toys') == "string")
			{
				scanned_toys = JSON.parse(Cookies.get('scanned_toys'));
			}
		}catch(e){
			console.error('JSON parsing error for scanned_toys');
		}
		if( Array.isArray(scanned_toys) && scanned_toys.length >= 1 )
			this.$set(this,'toys',scanned_toys);

		this.current_user_fullname = Cookies.get('userfullname')

		let add = this.get_url_arg('add');
		if( add )
		{
			if( this.toys.indexOf(parseInt(add)) == -1 )
			{
				let v = this;
				this.api.get({
					url: '/toy_by_number/'+String(add),
					success: function(response){
						if( true || v.toys.indexOf(response.toy_id) == -1 )  //eslint-disable-line
						{
							v.toys.push(response.toy_id);
							Cookies.set('scanned_toys',v.toys);
							v.toast('Toy Added to List','success');
						}
						else
							v.toast('Toy Already In List','warning');
						v.kb_text = null;
					},
					fail: function(x,s,m){ 
						let msg = 'Unable to load Toy';
						if( x.status == 404 )
							v.toast('The Scanned Toy Number is Invalid, Please try to scan your toy again.','warning');
						else
							v.toast(x.statusText,'danger');
						console.error(msg,x,s,m);
					},
				});
			}
		}
	},
}
</script>
