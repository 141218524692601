<template>
	<div id="my-toys" class="container pt-5">
		<GiveModal ref="givemodal" @select="give_select" />
		<ConditionModal ref="conditionmodal" @select="condition_select" />
		<h2>My Toys</h2>
		<p>Toys in this list are toys which should either be <strong>in your physical possession</strong> or in the posession of <strong>one of your Client families.</strong></p>
		<hr />
		<div>
			<h5>Filters:</h5>
			<div class="row">
				<div class="col-sm-12 col-md-3" v-for="(f,index) in filters" :key="index">
					<div class="form-check">
						<input class="form-check-input" type="radio" :id="'radioFilter'+String(index)" :value="f.value" v-model="filter">
						<label class="form-check-label" :for="'radioFilter'+String(index)">
							{{f.label}}
						</label>
					</div>
				</div>
			</div>
		</div>
		<div v-if="toys_loaded">
			<div v-for="(toy_id,index) in toys" :key="index">
				<ToyCartItemAdvanced 
					:toy_id="toy_id" 
					:allow_give="true" 
					@give="give_click" 
					:allow_condition_changes="true" 
					@condition="condition_click" 
					:allow_retrieve="true" 
					@retrieve="retrieve_click(toy_id)" />
			</div>
		</div>
		<hr />
		<div class="text-right">
			<button type="button" class="btn btn-lg btn-info" @click="checkin_click">Check-In Toys</button>
		</div>
	</div>
</template>
<script>
	import ConditionModal from '@/components/ConditionModal.vue'
	import GiveModal from '@/components/GiveModal.vue'
	import {EventBus} from '../main'
	import ToyCartItemAdvanced from '@/components/ToyCartItemAdvanced.vue'

	export default{
		name: 'MyToys',
		props: [],
		components: {ToyCartItemAdvanced,GiveModal,ConditionModal},
		data(){
			return {
				toys_loaded: false,
				toys: [],
				selected_toy_id: null,
				filter: 'all',
				filters: [
					{value:'all',label:'All My Toys'},
					{value:'with-me',label:'Toys I Have'},
					{value:'with-clients',label:'Toys with My Clients'},
				],
			}
		},
		watch: {
			filter(){
				let v = this
				this.$nextTick(function(){
					v.load_toys()
				})
			},
		},
		methods: {
			retrieve_click(toy_id){
				// Handler for retrieving a toy from a client
				let v = this
				let index = v.toys.indexOf(toy_id)

				if( index == -1 )
				{
					v.nerivon_alert('Toy Not Found','An error has occurred, the toy you are trying to remove does not appear to be in the current list of toys. Contact the Developer to report this problem.','danger')
					return
				}

				v.nerivon_confirm('Retrieve toy from Client','Have you successfully retrieved this toy from the client?','info',function(answer){
					if( answer == true )
					{
						v.api.post({
							url: '/retrieve/'+String(toy_id),
							dataType: 'text',
							contentType: 'text',
							success(){
								EventBus.$emit('mytoys')
								v.load_toys()
							},
							fail(){
								console.log('Retrieve failed ')
							},
						})
					}
				})
				return toy_id
			},
			remove_click(toy_id){
				// Remove a Toy From the My Toys List
				let v = this
				v.nerivon_confirm('Remove Toy from My Toys','Are you sure you want to return this toy to the Library?','warning',function(answer){
					console.log(answer)
					if( answer )
					{
						let index = v.toys.indexOf(toy_id)
						if(index > -1)
							v.toys.splice(index,1)
						v.api.post({
							url: '/return/'+String(toy_id),
							dataType: 'text',
							contentType: 'text',
							success(response){
								if( response.result == true )
								{
									EventBus.$emit('mytoys')
									v.toast('Toy Returned Successfully','success')
									v.load_toys()
								}
							},
							fail(){
								console.log('remove failed')
							},
						})
					}
				})
			},
			give_click(toy_id){
				// Opens the GIVE to Client modal
				this.selected_toy_id = toy_id
				this.$refs.givemodal.open()
			},
			give_select(client_id){
				// Handles the dialog's action after a client is selected
				let v = this
				v.api.post({
					url: '/give/'+String(v.selected_toy_id)+'/'+String(client_id),
					dataType: 'text',
					contentType: 'text',
					success: function(){
						EventBus.$emit('cart')
						EventBus.$emit('mytoys')
						v.toast('Toy Given to Client','success')
						v.load_toys()
					},
					fail(){
						console.log('give failed')
					},
				})
			},
			checkin_click(){
				this.$router.push('/checkin')
			},
			load_toys(){
				let v = this
				v.toys_loaded = false
				v.api.get({
					url: '/mytoys',
					data: {filter: v.filter},
					success(response){
						v.$nextTick(function(){
							v.toys = response.mytoys
							v.toys_loaded = true
						})
					},
				})
			},
			condition_click(toy_id,condition){
				this.$refs.conditionmodal.open(toy_id,condition)
			},
			condition_select(toy_id,condition,notes){
				let v = this
				if( toy_id && condition )
				{
					v.api.post({
						url: '/condition/'+String(toy_id),
						data: { condition: condition, notes: notes },
						dataType: 'text',
						success(){
							v.load_toys()
							EventBus.$emit('mytoys')
						},
						fail(){
							v.toast('Failed to Update Condition','danger')
						},
					})
				}
				return toy_id
			},
		},
		mounted(){
			this.load_toys()
		},
	}
</script> 
