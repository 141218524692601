import jQuery from 'jquery'
import {settings} from './settings.js'
import Cookies from 'js-cookie'
import router from './router'

function parse_params(params){
	var opt = {
		method: params.method != undefined ? params.method : 'GET',
		url: params.url != undefined ? params.url : '',
		data: params.data != undefined ? params.data : {},
		dataType: params.dataType != undefined ? params.dataType : 'json',
		success: params.success != undefined ? params.success : null,
		fail: params.fail != undefined ? params.fail : null,
		processData: params.processData != undefined ? params.processData : true,
		contentType: params.contentType != undefined ? params.contentType : 'application/x-www-form-urlencoded; charset=UTF-8',
		cache: params.cache != undefined ? params.cache : false,
	}

	// Conditional to allow Automatic ContentType assignment
	if(params.contentType == undefined)
		delete opt.contentType;

	// Default Specified Condition in Docs
	if( opt.dataType == 'script' || opt.dataType == 'jsonp' )
		opt.cache = false;
	return opt;
}

function ajax(params){
	var opt = parse_params(params);

	//TODO add detection of the base protocol http: prefix in the URL
	//		if one isn't present, then add the base protocol.
	//		basically if the url doesn't begin with http, then assume you have to add it
	// console.log('ajax',params);

	// DO NOT DO THIS, EVER: Read the note below, Stupid.
	// Detect JSON Explicitness and try to enforce the right content-type
	// if(opt.dataType == 'json' && opt.contentType == undefined)
	// {
	// 	opt.contentType = 'application/json; charset=UTF-8';
	// }
	// NOTE TO FUTURE DUMB SELF: the default for JQuery Ajax is 'application/x-www-form-urlencoded; charset=UTF-8'
	// You mustn't try to make it automatically detect JSON format explicitly because the dataType parameter 
	// refers to the format of the data COMING BACK from the server, and has nothing to do with formatting data
	// going OUT with your request headers. Just learn the headers you foolish man.

	// ADDENDUM STUPID:
	// OK, we're going to shortcut this using a keyword, SO 'json" for the contentType parameter will now 
	// Auto translate to - application/json; charset=UTF-8

	// Shortcut by keyword
	if(opt.contentType == 'json' || opt.contentType == 'JSON')
		opt.contentType = 'application/json; charset=UTF-8';

	jQuery.ajax({
		method: opt.method,
		url: settings.api_host + opt.url,
		data: opt.data,
		dataType: opt.dataType,
		xhrFields: {
			withCredentials: true
		},
		crossDomain: true,
		processData: opt.processData,
		contentType: opt.contentType,
		beforeSend: function(xhr) {
			xhr.setRequestHeader("X-CSRF-TOKEN", Cookies.get("csrf_access_token"));
		}
	}).done(function(response){
		if(typeof opt.success == "function")
		{
			opt.success(response);
		}
		else if(typeof opt.done == "function")
		{
			opt.done(response);
		}
		if(typeof opt.always == 'function')
		{
			opt.always(); // This is setup here because jQuery.ajax.always does not work for CORS calls
		}
	}).fail(function(xhr,status,message){
		if(xhr.status == 401 || xhr.status == 403)
		{
			Cookies.set('redirect_page',window.location.href);
			router.push('/login');
		}
		else
		{
			if(typeof opt.fail == "function")
			{
				opt.fail(xhr,status,message);
			}else{
				console.error('jQuery Ajax Request Failed',xhr,status,message);
			}
		}
		if(typeof opt.always == 'function')
		{
			opt.always(); // This is setup here because jQuery.ajax.always does not work for CORS calls
		}
	});
}

export default ajax;
export {ajax, parse_params};
