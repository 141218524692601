<template>
	<div class="user">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-person-circle /> User {{record.username}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<b-form>
				<b-row>
					<b-col md=6 sm="12">
						<fieldset><legend>Personal Information</legend>
							<b-form-group id="firstname" label="First Name" label-for="input-2">
								<b-form-input id="input-2" v-model="record.firstname" required></b-form-input>
							</b-form-group>
							<b-form-group id="lastname" label="Last Name" label-for="input-3">
								<b-form-input id="input-3" v-model="record.lastname" required></b-form-input>
							</b-form-group>
							<b-form-group id="email" label="Email Address" label-for="input-4">
								<b-form-input id="input-4" v-model="record.email" required></b-form-input>
							</b-form-group>
							<b-form inline>
								<b-form-group id="phone1" label="Phone Contacts">
									<b-form-input id="input-4" v-model="record.phone1_caption" placeholder="eg. Home" required></b-form-input>
									<b-form-input id="input-5" v-model="record.phone1" placeholder="eg. 306-000-0000" required></b-form-input>
									<br />
									<b-form-input id="input-6" v-model="record.phone2_caption" placeholder="eg. Work" required></b-form-input>
									<b-form-input id="input-7" v-model="record.phone2" placeholder="eg. 306-000-0000" required></b-form-input>
								</b-form-group>
							</b-form>
						</fieldset>
						<hr />
						<fieldset><legend>Stat Tracking App Integration</legend>
							<div class="form-group">
								<label for="r_user_id">Linked User Account</label>
								<div class="input-group">
									<select id="r_user_id" v-model="record.r_user_id" class="form-control">
										<option v-for="u in stat_users" :key="u.user_id" :value="u.user_id">{{u.first_name}} {{u.last_name}}</option>
									</select>
									<div class="input-group-append">
										<button type="button" class="btn btn-primary" @click="record.r_user_id=null;load_stat_users();"><i class="bi bi-x-circle"></i> Clear Link</button>
									</div>
								</div>
							</div>
						</fieldset>
					</b-col>
					<b-col md=6 sm="12">
						<fieldset v-if="security_allowed"><legend>Access Credentials</legend>
							<b-form-group id="account_id" label="Account" label-for="input-800">
								<b-form-select id="input-800" v-model="record.account_id" :options="options.accounts" required></b-form-select>
							</b-form-group>
							<b-form-group id="username" label="User Name" label-for="input-8">
								<b-form-input id="input-8" v-model="record.username" required></b-form-input>
							</b-form-group>
							<div v-if="!password_changing && record.user_id > 0">
								<div class="alert alert-primary" v-show="show_password_hint">
									You have created a new user and now must create a password for this user before they can login.
								</div>
								<div class="form-group">
									<label for="change_password">Password</label>
									<input type="text" class="form-control" v-model="new_password" id="change_password" required>
								</div>
								<div class="text-right">
									<button class="btn btn-warning" type="button" @click="set_pass">Set New Password</button>
								</div>
							</div>
							<div v-if="password_changing" class="text-center">
								<img src="/img/loading.gif" style="height: 200px;"> Changing Password, Please Wait..
							</div>
						</fieldset>
						
						<hr />
						<fieldset><legend>Security</legend>
							<p class="text-muted">Assigned security roles for the user which enable specific functionality.</p>
							<div v-if="options.roles_loaded">
								<div class="form-check" v-for="r in options.roles" :key="r.acl_role_id">
									<input type="checkbox" class="form-check-input" :id="'role_'+r.acl_role_id" :value="r.acl_role_id" v-model="record.roles">
									<label class="form-check-label" :for="'role_'+r.acl_role_id">{{r.role}}</label>
								</div>
							</div>
						</fieldset>
					</b-col>
				</b-row>
			</b-form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'User',
	components: {},
	data: function(){
		return {
			show_password_hint: false,
			options: {
				accounts: [],
				roles: [],
				roles_loaded: false,
			},
			loading: false,
			password_changing: false,
			new_password: null,
			record: {
				user_id: 0,
				r_user_id: null,
				account_id: 0,
				username: '',
				r_username: '',
				password: '',
				firstname: '',
				lastname: '',
				email: '',
				phone1: '',
				phone1_caption: '',
				phone2: '',
				phone2_caption: '',
				active: 1,
				activation: '',
				deactivation: '',
				created: '',
				roles: [],
			},
			stat_users: [],
		}
	},
	watch: {
		'record.r_user_id': function(uid,old_uid){
			if( uid == old_uid )
				return
			let v = this
			statuserloop:
			for(let i in v.stat_users)
			{
				let statuser = v.stat_users[i]
				if( statuser['user_id'] == uid )
				{
					v.record.r_username = statuser['username']
					break statuserloop
				}
			}
		},
	},
	computed: {
		security_allowed(){
			return this.aclCheck('ADMIN_USERS');
		},
	},
	methods: {
		load_stat_users(){
			let v = this;
			v.stat_users = [
				{user_id: null, first_name: '- - - Not Linked - - -', last_name: '', username: null, email: null}
				]
			v.api.get({
				url: '/admin/statusers',
				success: function(response){
					if(response.length)
						for(let i in response)
							v.stat_users.push(response[i])
				},
				fail: function(x,s,m){
					let msg = 'Unable to retrieve list of stat app users'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		cancel: function(){
			this.$router.push('/admin/users');
		},
		remove: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete User Account',
				'Are you sure you wish to delete this User Account? This operation cannot be un-done.',
				'danger',
				function(answer){
					v.loading = false;
					if( answer ){
						v.api.delete({
							url: '/admin/user/'+String(v.record.user_id),
							success: function(){
								v.toast('User Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								v.toast('User Deletion Failed','danger');
								console.error('User Deletion Failed',x,s,m)
							},
						});
					}
				}
			);
		},
		save: function(){
			var v = this;
			var new_user = v.record.user_id <= 0;
			v.loading = true;

			this.api.post({
				url: '/admin/user/'+String(this.record.user_id),
				data: JSON.stringify(this.record),
				contentType: 'json',
				success: function(response){
					let msg = ''
					if( new_user )
					{
						msg = 'New User Details Saved'
						v.show_password_hint = true
						v.load(response.user_id);
						v.$router.push('/admin/user/'+response.user_id);
					}
					else
					{
						msg = 'User Changes Saved'
					}
					
					v.toast(msg,'success');
					if( !new_user )
					{
						setTimeout(v.cancel,500);
					}
					else
					{
						v.loading = false;
					}
				},
				fail: function(x,s,m){
					v.toast('User Change Failed','danger');
					console.error('User Change Failed',x,s,m);
					v.loading = false;
				},
			});
		},
		set_pass(){
			let v = this;
			v.password_changing = true;
			v.show_password_hint = false;
			v.loading = true;
			v.api.post({
				url: '/admin/user/setpass/'+String(this.record.user_id),
				data: {'password':v.new_password},
				dataType: 'text',
				success: function(){
					v.new_password = null;
					v.toast('Password Set!','success')
					setTimeout(function(){v.password_changing = false;},500);
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Failed to Set New Password'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
					setTimeout(function(){v.password_changing = false;},500);
					v.loading = false;
				},
			})
		},
		load: function(id){
			var v = this;
			v.loading = true;
			this.api.get({
				url: '/admin/user/'+String(id),
				success: function(response){
					for(var i in response){
						v.record[i] = response[i];
					}
					if( !response['roles'] )
						v.record.roles = [];
					v.loading = false;
				},
				fail: function(x,s,m){
					v.toast('User Failed to Load','danger')
					console.error('User Failed to Load',x,s,m);
					v.loading = false;
				},
			});
		},
		fetch_accounts: function(){
			var v = this;
			v.api.get({
				url: '/admin/accounts',
				success: function(response){
					for(var i in response){
						v.options.accounts.push( {
							value: response[i].account_id,
							text: response[i].account_name,
						});
					}
				},
				fail: function(x,s,m){
					v.toast('Unable to load Accounts','danger');
					console.error('Unable to load Accounts',x,s,m);
				},
			});
		},
		fetch_roles(){
			var v = this;
			v.api.get({
				url: '/admin/roles',
				success: function(response){
					v.options.roles = response
					v.options.roles_loaded = true
				},
				fail: function(x,s,m){
					let msg = 'Failed to Retrieve Roles'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
	},
	mounted: function(){
		var id = this.$route.params.id
		this.fetch_accounts()
		this.fetch_roles()
		this.load_stat_users()
		this.load(id)
	},
}
</script>
