<template>
	<div class="toy_types">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Toy Types</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add"><b-icon-plus-square /> Add New Toy Type</button>
				</div>
			</div>
		</div>
		<ToyTypeList mode="admin"></ToyTypeList>
	</div>
</template>

<script>

import ToyTypeList from '@/components/ToyTypeList.vue'

export default {
	name: 'ToyTypes',
	components: {
		ToyTypeList,
	},
	data: function(){
		return {
			
		}
	},
	methods: {
		add: function(){
			this.$router.push('/admin/toy_type/0');
		},
	},
	mounted: function(){
	},
}
</script>
