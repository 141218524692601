
// Default Production Setting
var settings = {
	api_host: 'https://api.toys.ecip.ca',
}

// Current Demo Setting
if( String(window.location.hostname).includes('local.toys.ecip.ca') )
	settings['api_host'] = 'https://local.toys.ecip.ca:5555';

export {settings};
