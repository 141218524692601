
<template>
	<div class="clientview">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><i class="bi bi-building"></i> Client {{record.first_name}} {{record.last_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><i class="bi bi-box-arrow-left"></i> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="delete_client()"><i class="bi bi-x-square"></i> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save()"><i class="bi bi-box-arrow-in-down-right"></i> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-5">
			<form class="needs-validation" novalidate>
				<div class="form-row">
					<div class="col">
						<label for="client_id">Client ID</label>
						<input class="form-control" id="client_id" v-model="record.client_id" readonly />
					</div>
					<div class="col">
						<label for="remote_id">Remote ID</label>
						<input class="form-control" id="remote_id" v-model="record.r_client_id" readonly />
					</div>
					<div class="col">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="record.active"  id="active" />
							<label class="form-check-label" for="active">Active</label>
						</div>
					</div>
					<div class="col">
						<label for="user_id">Supervising Consultant</label>
						
						<select class="form-control" id="user_id" v-model="record.user_id">
							<option v-for="u in interventionists" :key="u.user_id" :value="u.user_id">{{u.firstname}} {{u.lastname}}</option>
						</select>
					</div>
				</div>
				<hr />
				<div class="form-row">
					<div class="col">
						<label for="first_name">First Name</label>
						<input class="form-control" id="first_name" v-model.trim="record.first_name"/>
						

					</div>
					<div class="col">
						<label for="middle_name">Middle Name(s)</label>
						<input class="form-control" id="middle_name" v-model="record.middle_name" />
					</div>
					<div class="col">
						<label for="last_name">Last Name(s)</label>
						<input class="form-control" id="last_name" v-model.trim="record.last_name" />
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Client',
	components: {},
	data: function(){ return {
		record: {
			first_name: null,
			last_name: null,
			client_id: null,
			active:0,
			user_id:null,
		},
		interventionists:[],
		loading:false,
	}},
	methods: {
		load_client(client_id){
			let v = this
			v.api.get({
				url: '/admin/client/'+String(client_id),
				success: function(response){
					v.record = response

				},
				fail: function(x,s,m){
					let msg = 'Failed to Load Client'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		load_users(){
			let v = this
			v.api.get({
				url: '/admin/users',
				success: function(response){
					v.interventionists = response
				},
				fail: function(x,s,m){
					let msg = 'Unable to load users list'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},


		save: function(prevent_redirect=false, callback=null){
			let v = this;

			var new_user = v.record.client_id || 0;

			let msg = 'Changes Saved'

				if(new_user == 0)
				{
					msg = 'New Client Created'
				}

			
			let data = {
				client: v.record,
			}

			v.api.post({
				url: '/admin/client/'+String(this.$route.params.id),
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json; charset=UTF-8',
				success: function(response){



					if( response.result )
					{
						v.toast(msg,'success');
						if(!prevent_redirect)
							setTimeout(v.cancel,500);
						if(callback != null)
							callback();
					}
					else
					{
						v.toast('Unable to Save Changes, Due to Validation failure','info')
						console.error(response.errors)
						let errors = response.errors
						for (var i = 0; i < errors.length; i++) 
						{ 
							let error = errors[i]
							v.toast(error['msg'],'danger')
						}


							

					}
				},
				fail: function(x,s,m){
					var msg = 'Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},







		
	},
	
	mounted: function(){
		let id = this.$route.params.id
		this.load_users()
		this.load_client(id)
	},
}
</script>