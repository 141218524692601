<template>
	<div class="roles">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><i class="bi bi-building"></i> Role {{record.role}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><i class="bi bi-box-arrow-left"></i> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="delete_role()"><i class="bi bi-x-square"></i> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save_role()"><i class="bi bi-box-arrow-in-down-right"></i> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<form>
				<h5>Role Details</h5>
				<hr />
				<div class="form-group">
					<label for="input-1">Role Name</label>
					<input type="text" class="form-control" v-model="record.role" required>
				</div>
				<div class="form-group">
					<label for="input-2">Description</label>
					<textarea class="form-control" v-model="record.description"></textarea>
				</div>
				<div class="row">
					<div class="col">
						<h5>Users</h5>
						<hr />
						<p class="text-muted">Users who have been assigned this role.</p>
						<div v-if="users_loaded">
							<div v-for="u in record.users" :key="u.user_id">
								<router-link :to="'/admin/user/'+u.user_id">
									<i class="bi bi-person-square"></i>
									{{u.firstname}}, {{u.lastname}}
								</router-link>
							</div>
						</div>
					</div>
					<div class="col">
						<h5>Permitted Actions</h5>
						<hr />
						<p class="text-muted">Users with this Role are able to use any of the selected actions.</p>
						<div v-if="actions_loaded">
							<div class="form-check" v-for="action in actions" :key="action.acl_action_id">
								<input class="form-check-input" type="checkbox" :value="action.acl_action_id" :id="'action'+action.acl_action_id" v-model="record.actions">
								<label class="form-check-label" :for="'action'+action.acl_action_id">{{action.action}}</label>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Role',
	components: {},
	data: function(){
		return {
			loading: false,
			actions: [],
			actions_loaded: false,
			users_loaded: false,
			record: {
				acl_role_id: 0,
				role: '',
				description: '',
				actions: [],
				users: [],
			}
		}
	},
	methods: {
		cancel: function(){
			this.loading = true;
			this.$router.push('/admin/roles');
		},
		delete_role: function(){
			let v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Role',
				'Are you certain you wish to remove this Role? This operation cannot be un-done.',
				'warning',
				function(answer){
					if( answer ) {
						v.api.delete({
							url: '/admin/role/'+String(v.record.acl_role_id),
							success: function(){
								v.toast('Role Deleted','warning');
								setTimeout(v.cancel,1000);
								v.loading = false;
							},
							fail: function(x,s,m){
								let msg = 'Role Deletion Failed';
								v.toast(msg,'danger');
								console.error(msg,x,s,m);
								v.loading = false;
							},
						});
					}
					else
					{
						v.loading = false;
					}
				}
			);
		},
		save_role: function(){
			var v = this;
			v.loading = true;
			v.api.post({
				url: '/admin/role/'+String(v.record.acl_role_id),
				data: JSON.stringify(v.record),
				dataType: 'json',
				contentType: 'json',
				success: function(){
					v.toast('Role Changes Saved','success');
					setTimeout(v.cancel,500);
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Role Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load_role: function(id){
			var v = this;
			v.loading = true;
			v.api.get({
				url: '/admin/role/'+String(id),
				success: function(response){
					for(var i in response){
						if( i != 'actions' && i != 'users' )
							v.record[i] = response[i];
					}
					v.record.actions = []
					for(i in response.actions)
					{
						v.record.actions.push(response.actions[i].acl_action_id);
					}
					v.record.users = []
					for(i in response.users)
					{
						v.record.users.push(response.users[i])
					}
					v.users_loaded = true;
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Role Failed to Load';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load_actions(){
			var v = this;
			this.api.get({
				url: '/admin/actions',
				success: function(response){
					v.actions = response;
					v.actions_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Failed to load Available Actions'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				}
			});
		},
	},
	mounted: function(){
		var id = this.$route.params.id;
		this.load_actions()
		this.load_role(id)
	},
}
</script>
