<template>
	<div class="status_condition_change_dialog">
		<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#StatusConditionChangeDialog" >
			<i class="bi bi-pencil">
			Update
			<!-- {{this.toy_id}} -->
			</i>
		</button>
		<div class="modal fade" id="StatusConditionChangeDialog" tabindex="-1" aria-labelledby="StatusConditionChangeDialog_Label" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" aria-label="StatusConditionChangeDialog_Label">Change Status &amp; Condition</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form>
							<div class="form-row">
								<div class="col-md-7">
									<div class="form-group">
										<label for="status">Status</label>
										<select id="status" class="form-control" v-model="p_status" @change="required=false">
											<option v-for="(status,key) in statuses" :value="key" :key="key">{{status}}</option>
										</select>
										<div class="badge badge-light">{{statuses[status]}}</div>
									</div>
								</div>
								<div class="col-md-5">
									<div class="form-group">
										<label for="condition">Condition</label>
										<select id="condition" class="form-control" v-model="p_condition">
											<option v-for="(cond,key) in conditions" :value="key" :key="key">{{cond}}</option>
										</select>
										<div class="badge badge-light">{{conditions[condition]}}</div>
									</div>
								</div>


								<div class="form-row" v-if="p_status=='to_interventionist'">

									<div class="col-md-12">
										<div class="form-group">
											<!-- <div class="badge badge-danger">* Selection Required</div> -->
											<label for="interventionist">Consultants</label>
											
											<select id="interventionist" class="form-control" v-model="p_interventionist" disabled>
												<option value="0" disabled selected>Choose Consultant</option>
												<option v-for="(interventionist,key) in interventionists" :value="interventionist.user_id" :key="key">{{interventionist.firstname}} {{interventionist.lastname}}</option>
											</select>
											<div class="badge badge-danger" v-if="required">* Selection Required</div>
										</div>
									</div>
								</div>
							
								<div class="form-row" v-if="p_status=='to_client'">
									<div class="col-md-12">
										<div class="form-group">
											<label for="client">Client</label>
											<select id="client" class="form-control" v-model="p_client">
											<!-- <select id="client" class="form-control" v-model="clientValue"> -->

												<option value="0" disabled selected>Choose Client</option>
												<option v-for="(client,key) in clients" :value="client.client_id" :key="key">{{client.first_name}} {{client.last_name}}</option>
											</select>
											<div class="badge badge-danger" v-if="required">* Selection Required</div>
										</div>
									</div>
								</div>


								<div class="form-row" v-if="p_status=='to_vendor_repair' || p_status=='to_vendor_return' || p_status=='to_vendor_cleaning'">			
									<div class="col-md-12">
										<div class="form-group">
											<label for="vendor_id">Preferred Vendor</label>
											<select id="vendor_id" v-model="p_vendor" class="form-control">
												<option value="" disabled selected>Select Vendor</option>
												<option v-for="v in vendors" :key="v.vendor_id" :value="v.vendor_id">{{v.vendor_name}}</option>
											</select>
											<div class="badge badge-danger" v-if="required">* Selection Required</div>
										</div>
									</div>
								</div>



								
								<div class="col-md-12">
									<div class="form-group">
										<label for="history_notes">Reason / Explanation / Notes on Condition</label>
										<textarea class="form-control" v-model="notes"></textarea>
									</div>
								</div>
							</div><!--row-->
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						<button type="button" class="btn btn-primary" @click="save_change(); $emit('status-condition-changed');">Save changes</button>
						<!-- <button type="button" class="btn btn-primary" data-dismiss="modal" @click="save_change(); $emit('status-condition-changed');">Save changes</button> -->

					</div>
				</div>
			</div>
		</div><!--endofmodal-->
	</div>
</template>

<script>
import $ from "jquery"
export default {
	name: 'UserStatusConditionChangeDialog',
	data: function(){
		return {
			d_visible: false,
			statuses: {
				'':'No Change',
				'to_library':'Added to Library',
				'to_client':'Given to Client',
				'to_interventionist':'Withdrawn by Consultant',
				'to_retirement':'Retired/Removed from Library',
				'to_vendor_repair':'Sent to Vendor for Repair',
				'to_vendor_return':'Sent to Vendor for Return',
				'to_vendor_cleaning':'Sent to Vendor for Cleaning'
			},
			conditions: {
				'': 'No Change',
				'good': 'Good',
				'needs_repair': 'Need Repair',
				'needs_replacement': 'Needs Replacement',
				'needs_cleaning': 'Needs Cleaning',
				'missing_pieces': 'Missing Pieces',
			},
			p_condition: this.condition,
			p_status: this.status,
			p_interventionist:this.interventionist||0,
			p_client:this.client||0,
			p_vendor:this.vendor||0, 
			p_toy_id:this.toy_id || 0,
			notes: null,
			interventionists:[],
			clients:[],
			vendors:[],

			m_toy_id:0,
			
			required: false,
		}
	},
	props: {
		toy_id: Number,
		condition: String,
		status: String,
		interventionist: Number,
		client: Number
	},
	methods: {
		toggle_dialog(){
			this.d_visible = !this.d_visible
		},
		fetch_status_types(){

		},
		load_interventionists(){
			let v = this;
			v.api.get({
				url: '/admin/users',
				success: function(response){
					if(response.length)
						for(let i in response)
							v.interventionists.push(response[i])
				},
				fail: function(x,s,m){
					let msg = 'Unable to retrieve list of Consultants'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},

		load_clients(){
			let v = this;
			v.api.get({
				url: '/admin/clients',
				success: function(response){
					if(response.length)
						for(let i in response)
							v.clients.push(response[i])
				},
				fail: function(x,s,m){
					let msg = 'Unable to retrieve list of Clients'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},

		load_vendors(){
			let v = this
			v.api.get({
				url: '/vendors',
				success: function(response){
					v.vendors = []
					for(var i in response){
						var vendor = response[i]
						v.vendors.push(vendor)
					}
				},
				fail: function(x,s,m){
					var msg = 'Failed to Load Vendors List'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			});
		},



		save_change(){
			let v = this;

			let record = {
				toy_id: v.p_toy_id,
				condition: v.p_condition,
				status: v.p_status,
				notes: v.notes,
				client: v.p_client,
				interventionist:v.p_interventionist,
				vendor:v.p_vendor,

			}


			if(v.p_status == 'to_vendor_repair' || v.p_status == 'to_vendor_return' || v.p_status == 'to_vendor_cleaning')
			{
				if(v.p_vendor.length === 0)
				{
					return v.required = true;
				}
			}

			this.api.post({
				url: '/admin/add_history',
				data: record,
				method: 'post',
				dataType: 'json',
				success: function(){
					// v.p_status = ''
					// v.p_notes = ''
					let msg = 'Status or Condition Change Saved'
					v.toast(msg,'success')
					v.$emit('saved')
					$('#StatusConditionChangeDialog').hide()
					$('.modal-backdrop').remove()
					$('body').removeClass()




				},
				fail: function(x,s,m){
					let msg = 'Saving Changes Failed'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				}
			})
		},
	},
	mounted:function(){
		this.load_interventionists();
		this.load_clients();
		this.load_vendors();
	}
}
</script>

