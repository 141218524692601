<template>
	<div class="accounts">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Account {{record.account_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="delete_account()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save_account()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<b-form>
				<b-form-group id="account_name" label="Account Name" label-for="input-1">
					<b-form-input id="input-1" v-model="record.account_name" required></b-form-input>
				</b-form-group>
			</b-form>
			<hr />
			<div class="row">
				<div class="col">
					<h3>Users</h3>
				</div>
				<div class="col">
					<h3>Toy Statistics</h3>
					<p>Toys in the Library ( <strong>43</strong> )</p>
					<p>Toys Available ( <strong>10</strong> / 43 )</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Accounts',
	components: {},
	data: function(){
		return {
			loading: false,
			record: {
				account_id: 0,
				account_name: null,
			}
		}
	},
	methods: {
		cancel: function(){
			this.$router.push('/admin/accounts');
		},
		delete_account: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Account/Organization',
				'Are you sure you wish to delete this account? If there are any user accounts associated with it, you will have to delete them before you will be allowed to continue. Remember this operation cannot be undone so make sure you are ready before you delete the account.',
				'danger',
				function(answer){
					if(answer){
						v.api.delete({
							url: '/admin/account/'+String(v.record.account_id),
							success: function(){
								v.toast('Account Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								v.toast('Account Deletion Failed','danger');
								console.error('Account Deletion Failed',x,s,m)
							},
						});
					}
					v.loading = false;
				}
			);
		},
		save_account: function(){
			var v = this;
			v.loading = true;
			v.api.post({
				url: '/admin/account/'+String(v.record.account_id),
				data: v.record,
				success: function(){
					v.toast('Account Changes Saved','success');
					setTimeout(v.cancel,500);
					v.loading = false;
				},
				fail: function(x,s,m){
					v.toast('Account Change Failed','danger');
					console.error('Account Change Failed',x,s,m);
					v.loading = false;
				},
			});
		},
		load_account: function(id){
			var v = this;
			v.loading = true;
			v.api.get({
				url: '/admin/account/'+String(id),
				success: function(response){
					for(var i in response){
						v.record[i] = response[i];
					}
					v.loading = false;
				},
				fail: function(x,s,m){
					v.toast('Account Failed to Load','danger')
					console.error('Account Failed to Load',x,s,m);
					v.loading = false;
				},
			});
		},
	},
	mounted: function(){
		var id = this.$route.params.id;
		this.load_account(id)
	},
}
</script>
