<template>
	<div class="category">
		<div v-if="!record_loaded">
			<h1>Loading..</h1>
		</div>
		<div class="bg-dark text-light p-3" v-if="record_loaded">
			<div class="row">
				<div class="col-md-6">			
					<h1><i class="bi bi-person-circle"></i> Category {{record.category_name}}</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-secondary" :disabled="loading" @click="cancel()"><b-icon-box-arrow-left /> Cancel</button>&nbsp;
					<button type="button" class="btn btn-danger" :disabled="loading" @click="remove()"><b-icon-x-square /> Delete</button>&nbsp;
					<button type="button" class="btn btn-primary" :disabled="loading" @click="save()"><b-icon-box-arrow-in-down-right /> Save Changes</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<form>
				<div class="form-row">
					<div class="col">
						<div class="form-group">
							<label for="category_name">Category Name</label>
							<input id="category_name" type="text" class="form-control" v-model="record.category_name" />
							<small class="form-text text-muted">The name of your category.</small>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="col-md-2">
						<p><strong>Optional Fields</strong></p>
						<div class="form-check">
							<input type="checkbox" class="form-check-input" v-model="checks.brand" id="chk_brand">
							<label for="chk_brand" class="form-check-label">Brand</label>
						</div>
						<div class="form-check">
							<input type="checkbox" class="form-check-input" v-model="checks.author" id="chk_author">
							<label for="chk_author" class="form-check-label">Author</label>
						</div>
					</div>
					<div class="col-md-10">
						<div class="form-group">
							<label for="description">Description</label>
							<textarea id="description" v-model="record.description" rows="5" class="form-control"></textarea>
							<small class="form-text text-muted">General notes on this category.</small>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Category',
	components: {},
	data: function(){
		return {
			loading: false,
			record_loaded: false,
			record: {},
			checks: {
				brand: false,
				author: false,
			},
			options: {
				accounts: [],
			}
		}
	},
	methods: {
		cancel: function(){
			this.loading = true;
			this.$router.push('/admin/categories');
		},
		remove: function(){
			var v = this;
			v.loading = true;
			v.nerivon_confirm(
				'Delete Category',
				'Are you sure?',
				'danger',
				function(answer){
					v.loading = false;
					if( answer) {
						v.api.delete({
							url: '/admin/category/'+String(v.record.category_id),
							success: function(){
								v.toast('Category Deleted','warning');
								setTimeout(v.cancel,1000);
							},
							fail: function(x,s,m){
								let msg = 'Category Deletion Failed';
								v.toast(msg,'danger');
								console.error(msg,x,s,m)
							},
						});
					}
				});
		},
		save: function(){
			let v = this;
			let id = (this.record.category_id ? this.record.category_id : 0);
			v.loading = true;

			// Convert Boolean Checkbox values to numeric booleans
			for(let key in v.checks)
			{
				v.record['field_'+key] = v.checks[key] ? 1 : 0
			}

			v.api.post({
				url: '/admin/category/'+String(id),
				data: v.record,
				success: function(){
					v.toast('Category Changes Saved','success');
					setTimeout(v.cancel,500);
					v.loading = false;
				},
				fail: function(x,s,m){
					let msg = 'Category Change Failed';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
					v.loading = false;
				},
			});
		},
		load: function(id){ 
			var v = this;
			v.record_loaded = false;
			this.api.get({
				url: '/admin/category/'+String(id),
				success: function(response){
					for(let i in response){
						v.record[i] = response[i]
					}
					for(let i in v.checks){
						v.checks[i] = response['field_'+i]
					} 
					v.toast('Category Loaded')
					v.record_loaded = true;
				},
				fail: function(x,s,m){
					let msg = 'Category Failed to Load';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
		fetch_accounts: function(){
			var v = this;
			v.api.get({
				url: '/admin/accounts',
				success: function(response){
					for(var i in response){
						v.options.accounts.push( {
							value: response[i].account_id,
							text: response[i].account_name,
						});
					}
				},
				fail: function(x,s,m){
					let msg = 'Unable to load Accounts';
					v.toast(msg,'danger');
					console.error(msg,x,s,m);
				},
			});
		},
	},
	mounted: function(){
		var id = this.$route.params.id;
		this.fetch_accounts();
		this.load(id)
	},
}
</script>
