<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="primary">
			<b-navbar-brand to="/"><img src="../assets/ecip-logo-web.png" style="max-height: 35px;" alt="ECIP LOGO">&nbsp;Toy Library</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>
				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto" >
					<b-nav-item to="/picks" v-show="logged_in" right>
						<b-icon-cart /> My Pick List ({{picklistcount}})
					</b-nav-item>

					<b-nav-item to="/mytoys" v-show="logged_in" right>
						<i class="bi bi-bag"></i> My Toys ({{mytoycount}})
					</b-nav-item>

					<b-nav-item-dropdown v-show="logged_in" right v-if="aclCheck('ADMIN_ACCOUNTS,ADMIN_USERS,ADMIN_ROLES,ADMIN_TOYS,ADMIN_TOY_TYPES,ADMIN_VENDORS,ADMIN_TAGS')">
						<template #button-content>
							<em><b-icon-cpu /> Admin</em>
						</template>
						<b-dropdown-item v-if="aclCheck('ADMIN_ACCOUNTS')" to="/admin/accounts">Accounts</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_USERS')" to="/admin/users">Users</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_ROLES')" to="/admin/roles">Roles and Actions</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_CLIENTS')" to="/admin/clients">Manage Clients</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_TOYS')" to="/admin/toys">Manage Toy Inventory</b-dropdown-item>
						<!-- <b-dropdown-item v-if="aclCheck('ADMIN_TOY_TYPES')" to="/admin/toy_types">Manage Toy Types</b-dropdown-item> -->
						<b-dropdown-item v-if="aclCheck('ADMIN_VENDORS')" to="/admin/vendors">Manage Vendors</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_CATEGORIES')" to="/admin/categories">Manage Categories</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_TAGS')" to="/admin/tags">Manage Tags</b-dropdown-item>
						<b-dropdown-item v-if="aclCheck('ADMIN_USERS')" to="/admin/syspanel">System Panel</b-dropdown-item>
					</b-nav-item-dropdown>

					<b-nav-item-dropdown v-show="logged_in" right>
						<!-- Using 'button-content' slot -->
						<template #button-content>
							<em><b-icon-person-circle /> {{userfullname}} Logged in</em>
						</template>
						<b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<div v-show="logged_in">
			<b-navbar class="navbar-secondary" type="dark">
				<b-navbar-nav>
					<b-nav-item to="/browse"><b-icon-columns /> Browse Toys</b-nav-item>
					<b-nav-item to="/checkout"><i class="bi bi-box-arrow-up"></i> Check Out Toys</b-nav-item>
					<b-nav-item to="/checkin"><i class="bi bi-box-arrow-in-down"></i> Check In Toys</b-nav-item>
					<b-nav-item to="/about"><b-icon-question-square /> About</b-nav-item>
<!-- 						<b-nav-item href="#" disabled>Disabled</b-nav-item> -->
				</b-navbar-nav>
			</b-navbar>
		</div>
	</div>
</template>

<script>
import Cookies from 'js-cookie'
import {EventBus} from '../main'

export default {
	name: 'AppNav',
	props: {
		msg: String,
	},
	data: function(){
		return {
			mytoycount: 0,
			picklistcount: 0,
		}
	},
	created(){
		let v = this
		EventBus.$on('updateCart',(e) => {
			v.toast(e,'info')
		})
		EventBus.$on('cart',() => {
			v.get_picklist_count()
		})
		EventBus.$on('mytoys',() => {
			v.get_mytoy_count()
		})
	},
	computed: {
		logged_in(){
			let s = (Cookies.get("access_token_cookie") != "")
			return Boolean(s)
		},
		user_id(){
			return Cookies.get('user_id')
		},
		userfullname(){
			return Cookies.get('userfullname')
		},
	},
	methods: {
		logout(){
			let v = this;
			v.api.get({
				url: '/logout',
				dataType: 'text',
				success: function(){
					Cookies.remove('user_id')
					Cookies.remove('userfullname')
					Cookies.remove('actions')
					window.location.href = '/login'
				},
				fail: function(x,s,m){
					let msg = 'Error: Unable to Logout'
					v.toast(msg,'danger')
					console.error(msg,x,s,m)
				},
			})
		},
		get_picklist_count(){
			let v = this
			v.api.get({
				url: '/picklistcount',
				success(response){
					v.picklistcount = response.total
				},
			})
		},
		get_mytoy_count(){
			let v = this
			v.api.get({
				url: '/mytoycount',
				success(response){
					v.mytoycount = response.total
				},
			})
		},
	},
	mounted(){
		this.get_mytoy_count()
		this.get_picklist_count()
	},
	
}
</script>