<template>
	<div class="accounts">
		<div class="bg-dark text-light p-3">
			<div class="row">
				<div class="col-md-6">			
					<h1><b-icon-building /> Accounts</h1>
				</div>
				<div class="col-md-6 text-right">
					<button type="button" class="btn btn-primary" @click="add_new"><b-icon-plus-square /> Add New Account</button>
				</div>
			</div>
		</div>
		<div class="container mt-3">
			<table class="table table-hover table-hover-pointer">
				<thead>
					<tr>
						<th>Account Name</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="account in accounts" v-bind:key="account.account_id" @click="edit_account(account.account_id)">
						<td>{{account.account_name}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Accounts',
	components: {},
	data: function(){
		return {
			accounts: [],
		}
	},
	methods: {
		add_new: function(){
			this.$router.push('/admin/account/0');
		},
		edit_account: function(id){
			this.$router.push('/admin/account/'+String(id));
		},
		load_accounts: function(){
			var v = this;
			this.api.get({
				url: '/admin/accounts',
				success: function(response){
					v.accounts = response;
				},
				fail: function(x,s,m){
					console.error('failed',x,s,m);
				},
			});
		},
	},
	mounted: function(){
		this.load_accounts()
	},
}
</script>
